import http from '@/utils/request'
// 物流竞价商品列表
export const listTbGoods = (data) => http.post("/trade/tradeTransportBidding/listTbGoods", data)
// 添加物流竞价商品
export const addTbGoods = (data) => http.post("/trade/tradeTransportBidding/addTbGoods", data)
// 物流竞价详情
export const getTbGoodsDetail = (data) => http.post(`/trade/tradeTransportBidding/getTbGoodsDetail?goodsId=${data}`)
// 查询企业状态
export const getEnterpriseStatus = (data) => http.post(`/trade/tradeTransportBidding/getEnterpriseStatus?goodsId=${data}`)
// 上传资料
export const uploadInformation = (data) => http.post("/trade/tradeTransportBidding/uploadInformation", data)
// 成为物流服务商
export const applyToBeService = (data) => http.post("/system/authCompany/applyToBeService", data)
// 资料审核
export const auditInformation = (data) => http.post("/trade/tradeTransportBidding/auditInformation", data)
// 订单列表
export const listTbOrder = (data) => http.post("/trade/tradeTransportBidding/listTbOrder", data)
// 竞价公司列表
export const tradeOfferpage = (data) => http.post("/trade/tradeOffer/page", data)
// 订单详情
export const listTbOrderDeatil = (data) => http.post(`/trade/tradeTransportBidding/listTbOrderDeatil?orderId=${data}`)
