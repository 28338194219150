<template>
  <div class="order_tabs">
    <el-tabs
      v-model="activeName"
      stretch
      type="card"
      style="width: 1200px; margin: 0 auto"
      @tab-click="handleClick"
    >
      <el-tab-pane label="合同管理" name="first" :disabled="firstDisabled">
        <div class="first">
          <particulars></particulars>
        </div>
        <div class="content" v-if="isPublisher == 'true'">
          <div class="uploading">
            <div class="uploading_all">
              <div class="shangchuang"></div>
              <div class="unpload">合同上传</div>
            </div>
            <div></div>
          </div>
          <div class="stencil">
            <el-upload
              class="upload-demo"
              action="#"
              :before-upload="beforeAvatarUpload"
              ref="upload"
              :on-change="handleChange"
              :limit="1000"
              :http-request="handleImageBeforeUpload"
              :file-list="fileList"
              :before-remove="beforeRemove"
              :on-exceed="handleExceed"
              v-if="
                (contrac.contractStatus == -1 || contrac.contractStatus == 0) &&
                contrac.contractType != 2
              "
            >
              <el-button
                v-if="contrac.contractStatus == -1"
                size="small"
                class="hy-btn"
                >上传线下合同
              </el-button>
              <el-button
                style="background: none; border: none"
                v-if="contrac.contractStatus == 0"
                size="small"
                class="hy-btn"
                >重新上传</el-button
              >
            </el-upload>
            <div
              class="template hy-btn"
              @click="selectTem"
              v-if="contrac.contractStatus == 0 && contrac.contractType == 2"
            >
              重新上传
            </div>
            <el-button
              class="hy-btn"
              @click="addcontract"
              v-if="
                contrac.contractStatus == -1 ||
                (contrac.contractStatus == 0 && contrac.contractType == 1)
              "
              style="margin-left: 10px"
              >提交合同</el-button
            >
            <div v-if="contrac.contractStatus == 1" style="line-height: 40px">
              发布方已确认,等待参与方确认
            </div>
            <div v-if="contrac.contractStatus == 2" style="line-height: 40px">
              参与方已确认,等待发布方确认
            </div>
            <div
              v-if="contrac.contractStatus == 3"
              style="margin-right: 10px; font-size: 20px; line-height: 40px"
            >
              双方已确认
            </div>
            <div
              style="cursor: pointer"
              class="stencil_preview button_on hy-btn"
              @click="accountClickss(contrac)"
              v-if="contrac.contractStatus != -1"
            >
              预览
            </div>
            <div
              style="cursor: pointer"
              class="template hy-btn"
              @click="selectTem"
              v-if="contrac.contractStatus == -1"
            >
              选择合同模板
            </div>
            <div
              class="template hy-btn"
              @click="countersign"
              v-if="contrac.contractStatus == 0 || contrac.contractStatus == 2"
            >
              确认合同信息
            </div>
          </div>
          <div
            style="line-height: 40px; font-size: 20px"
            v-if="contrac.contractStatus == 0"
          >
            已上传
          </div>
        </div>
        <div class="content" v-if="isPublisher == 'false'">
          <div class="uploading">
            <div class="uploading_all">
              <div class="shangchuang"></div>
              <div class="unpload">合同上传</div>
            </div>
            <div></div>
          </div>
          <div class="stencil">
            <div v-if="contrac.contractStatus == -1" style="line-height: 40px">
              未上传，请等待对方上传合同!
            </div>
            <div
              v-if="contrac.contractStatus != -1 && contrac.contractStatus != 3"
              style="margin-right: 10px; line-height: 40px"
            >
              已上传
            </div>
            <div
              style="margin-left: 10px; line-height: 40px"
              v-if="contrac.contractStatus == 3"
            >
              双方已确认
            </div>

            <div
              class="stencil_preview button_on hy-btn"
              @click="accountClickss(contrac)"
              v-if="contrac.contractStatus != -1"
            >
              预览
            </div>
            <div
              :class="
                contrac.contractStatus == 0 || contrac.contractStatus == 1
                  ? 'template hy-btn'
                  : ''
              "
              @click="countersign"
              v-if="contrac.contractStatus == 0 || contrac.contractStatus == 1"
              style="line-height: 40px; cursor: pointer"
            >
              确认合同信息
            </div>
            <div
              style="margin-left: 10px; font-size: 20px; line-height: 40px"
              v-if="contrac.contractStatus == 0"
            >
              发布方已上传,等待参与方确认
            </div>
          </div>
          <!-- <div
            style="margin-top: 20px; font-size: 30px"
            v-if="contrac.contractStatus == 0"
          >
            已上传
          </div> -->
        </div>
      </el-tab-pane>
      <!-- 在线签章 -->
      <el-tab-pane
        label="在线签章"
        name="three"
        :disabled="threeDisabled"
        v-if="contrac.contractType == 2 && contrac.contractStatus == 3"
      >
        <div class="first">
          <particulars></particulars>
        </div>
        <div class="content"></div>
        <div class="uploading">
          <div class="uploading_all">
            <div class="shangchuang"></div>
            <div class="unpload">在线签章</div>
          </div>
          <div></div>
        </div>
        <div class="sing-btns">
          <el-button
            @click="confirmClicks"
            type="primary"
            v-if="
              (contrac.contractStatus == 3 && contrac.signatureStatus == 0) ||
              (contrac.contractStatus == 3 &&
                contrac.signatureStatus == 2 &&
                isPublisher == 'true') ||
              (contrac.contractStatus == 3 &&
                contrac.signatureStatus == 1 &&
                isPublisher == 'false')
            "
            style="color: #ffffff"
            >在线签章</el-button
          >
        </div>
      </el-tab-pane>
      <!-- 支付 -->
      <el-tab-pane
        label="支付管理"
        name="third"
        :disabled="
          thirdDisabled ||
          (contrac.signatureStatus == null
            ? contrac.contractStatus != 3
            : contrac.signatureStatus != 3)
        "
      >
        <div class="first">
          <particulars @child-event="parentEvent" ref="parentref"></particulars>
        </div>
        <div class="content" v-if="isPublisher == 'true'">
          <div class="uploading">
            <div class="uploading_all">
              <div class="shangchuang"></div>
              <div class="unpload">支付记录</div>
            </div>
          </div>
          <div style="margin-top: 30px">
            <el-table :data="tableDatas" border style="width: 100%">
              <el-table-column
                prop="createTime"
                align="center"
                label="支付时间"
              >
              </el-table-column>
              <el-table-column prop="payPrice" align="center" label="单价(元)">
              </el-table-column>
              <el-table-column
                prop="payCount"
                align="center"
                label="支付数量(吨)"
              >
              </el-table-column>
              <el-table-column
                prop="payAmount"
                align="center"
                label="支付金额(元)"
              >
              </el-table-column>

              <el-table-column prop="payType" align="center" label="支付方式">
              </el-table-column>
              <el-table-column
                fixed="right"
                align="center"
                label="收款状态"
                width="200"
              >
                <template slot-scope="scope">
                  <div type="text" size="small">
                    {{ scope.row.payStatus == "待支付" ? "待收款" : "已收款" }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                fixed="right"
                align="center"
                label="付款凭证"
                width="200"
              >
                <template slot-scope="scope">
                  <el-button
                    @click="accountClick(scope.row.voucherUrl)"
                    v-if="
                      scope.row.voucherUrl && scope.row.payType != '线上支付'
                    "
                    type="text"
                    size="small"
                  >
                    查看付款凭证</el-button
                  >
                  <el-upload
                    v-if="
                      !scope.row.voucherUrl && scope.row.payType != '线上支付'
                    "
                    class="upload-demo"
                    action="#"
                    multiple
                    :http-request="addupdateVoucher"
                    :show-file-list="false"
                    accept=".bmp,.jpg,.png,.jpeg,.pdf"
                    :limit="1"
                  >
                    <el-button
                      size="small"
                      type="primary"
                      @click="addVoucher(scope.row)"
                      >上传凭证
                    </el-button>
                  </el-upload>
                </template>
              </el-table-column>
            </el-table>
            <div class="pattern" v-if="orderStatus != 2">
              <div @click="flineFormclick(1)" class="hy-btn">线上支付</div>
              <div @click="flineFormclick(2)" class="hy-btn">线下支付</div>
              <div class="hy-btn">承兑付款</div>
            </div>
          </div>
        </div>
        <div class="content" v-if="isPublisher == 'false'">
          <div class="uploading">
            <div class="uploading_all">
              <div class="shangchuang"></div>
              <div class="unpload">支付记录</div>
            </div>
          </div>
          <div style="margin-top: 30px">
            <el-table :data="tableDatas" border style="width: 100%">
              <el-table-column
                prop="createTime"
                align="center"
                label="支付时间"
                width="200"
              >
              </el-table-column>
              <el-table-column prop="payPrice" align="center" label="单价(元)">
              </el-table-column>
              <el-table-column
                prop="payCount"
                align="center"
                label="支付数量(吨)"
              >
              </el-table-column>
              <el-table-column
                prop="payAmount"
                align="center"
                label="支付金额(元)"
              >
              </el-table-column>

              <el-table-column prop="payType" align="center" label="支付方式">
              </el-table-column>
              <el-table-column
                fixed="right"
                align="center"
                label="收款状态"
                width="200"
              >
                <template slot-scope="scope">
                  <div type="text" size="small">
                    {{ scope.row.payStatus == "待支付" ? "待收款" : "已收款" }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                fixed="right"
                align="center"
                label="付款凭证"
                width="200"
                prop="payStatus"
              >
                <template slot-scope="scope">
                  <el-button
                    @click="accountClick(scope.row.voucherUrl)"
                    v-if="scope.row.payType != '线上支付'"
                    :disabled="!scope.row.voucherUrl"
                    type="text"
                    size="small"
                    >查看付款凭证</el-button
                  >
                </template>
              </el-table-column>

              <el-table-column
                fixed="right"
                align="center"
                label="操作"
                width="200"
                prop="payStatus"
              >
                <template slot-scope="scope">
                  <el-button
                    @click="addconfirm(scope.row)"
                    v-if="scope.row.payType != '线上支付'"
                    :disabled="scope.row.payStatus == '支付成功'"
                    type="text"
                    size="small"
                    >确认金额已到账
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </el-tab-pane>
      <!-- 运输 -->
      <el-tab-pane
        label="运输管理"
        name="fourth"
        :disabled="
          fourthDisabled ||
          (contrac.signatureStatus == null
            ? contrac.contractStatus != 3
            : contrac.signatureStatus != 3)
        "
        v-if="!isLogistics"
      >
        <div class="first">
          <particulars></particulars>
        </div>
        <div class="content">
          <div class="uploading">
            <div class="uploading_all">
              <div class="shangchuang"></div>
              <div class="unpload">物流运输</div>
            </div>
            <div style="display: flex">
              <div
                class="invoice hy-btn"
                @click="carriageClick"
                v-if="
                  (isPublisher == 'true' && deliveryMode == 2) ||
                  (isPublisher == 'false' && deliveryMode == 1)
                "
              >
                发起运输
              </div>
              <div
                style="margin-left: 10px"
                class="invoices hy-btn"
                @click="carriageClicks"
                v-if="
                  (isPublisher == 'true' && deliveryMode == 2) ||
                  (isPublisher == 'false' && deliveryMode == 1)
                "
              >
                服务商运输
              </div>
            </div>
          </div>
          <div style="margin-top: 30px">
            <el-table :data="tralist" border style="width: 100%">
              <el-table-column
                prop="createTime"
                align="center"
                label="运输时间"
              >
              </el-table-column>
              <el-table-column
                prop="trafficCount"
                align="center"
                label="运输数量(吨)"
              >
              </el-table-column>
              <el-table-column align="center" label="运输方式">
                <template slot-scope="scope">
                  {{ scope.row.trafficWay | formatType }}
                </template>
              </el-table-column>
              <el-table-column prop="startPlace" align="center" label="起始地">
              </el-table-column>
              <el-table-column prop="destPlace" align="center" label="目的地">
              </el-table-column>
              <el-table-column prop="contacts" align="center" label="联系人">
              </el-table-column>
              <el-table-column
                prop="contactInfo"
                align="center"
                label="联系方式"
              >
              </el-table-column>
              <el-table-column fixed="right" align="center" label="运输凭证">
                <template slot-scope="scope">
                  <el-button
                    @click="trafficDocUrlsClick(scope.row.trafficDocUrls)"
                    :disabled="scope.row.trafficDocUrls.length == 0"
                    type="text"
                    size="small"
                    >查看
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </el-tab-pane>
      <!-- 结算 -->
      <el-tab-pane
        label="结算管理"
        name="five"
        :disabled="
          fiveDisabled ||
          (contrac.signatureStatus == null
            ? contrac.contractStatus != 3
            : contrac.signatureStatus != 3)
        "
      >
        <div class="first">
          <particulars></particulars>
        </div>
        <div class="content" v-if="isPublisher == 'false'">
          <div class="uploading">
            <div class="uploading_all">
              <div class="shangchuang"></div>
              <div class="unpload">货物结算</div>
            </div>
          </div>
          <div style="margin-top: 30px">
            <el-table :data="signlist" border style="width: 100%">
              <el-table-column
                prop="createTime"
                align="center"
                label="结算时间"
              >
              </el-table-column>
              <el-table-column prop="signCount" align="center" label="结算数量">
              </el-table-column>
              <el-table-column
                fixed="right"
                align="center"
                label="结算状态"
                width="100"
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.deliverStatus == -1"> 取消结算 </span>
                  <span v-if="scope.row.deliverStatus == 0"> 待结算 </span>
                  <span v-if="scope.row.deliverStatus == 1"> 已结算 </span>
                  <span v-if="scope.row.deliverStatus == 2">
                    等待对方结算
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                fixed="right"
                align="center"
                label="结算单据"
                width="200"
                prop="payStatus"
              >
                <template slot-scope="scope">
                  <div>
                    <el-button
                      :disabled="!scope.row.invoiceUrl"
                      @click="accountClick(scope.row.invoiceUrl)"
                      type="text"
                      size="small"
                      >查看
                    </el-button>
                  </div>
                </template>
              </el-table-column>
              <!-- <el-table-column fixed="right" align="center" label="煤质检验单" width="200" prop="payStatus">
								<template slot-scope="scope">
									<div>
										<el-button :disabled="!scope.row.inspectUrl"
											@click="accountClick(scope.row.inspectUrl)" type="text" size="small">查看
										</el-button>
									</div>
								</template>
							</el-table-column> -->
              <el-table-column
                fixed="right"
                align="center"
                label="操作"
                width="100"
              >
                <template slot-scope="scope">
                  <el-button
                    :disabled="scope.row.deliverStatus == 1"
                    @click="receivingClickS(scope.row)"
                    type="text"
                    size="small"
                    >确认结算</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="content" v-if="isPublisher == 'true'">
          <div class="uploading">
            <div class="uploading_all">
              <div class="shangchuang"></div>
              <div class="unpload">货物结算</div>
            </div>
          </div>
          <div style="margin-top: 30px">
            <el-table :data="signlist" border style="width: 100%">
              <el-table-column
                prop="createTime"
                align="center"
                label="结算时间"
              >
              </el-table-column>
              <el-table-column prop="signCount" align="center" label="结算数量">
              </el-table-column>
              <el-table-column
                fixed="right"
                align="center"
                label="结算状态"
                width="100"
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.deliverStatus == -1"> 取消结算 </span>
                  <span v-if="scope.row.deliverStatus == 0"> 待结算 </span>
                  <span v-if="scope.row.deliverStatus == 1"> 已结算 </span>
                  <span v-if="scope.row.deliverStatus == 2">
                    等待对方结算
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                fixed="right"
                align="center"
                label="结算单据"
                width="200"
                prop="payStatus"
              >
                <template slot-scope="scope">
                  <div>
                    <el-button
                      :disabled="!scope.row.invoiceUrl"
                      @click="accountClick(scope.row.invoiceUrl)"
                      type="text"
                      size="small"
                      >查看
                    </el-button>
                  </div>
                </template>
              </el-table-column>
              <!-- <el-table-column fixed="right" align="center" label="煤质检验单" width="200" prop="payStatus">
								<template slot-scope="scope">
									<div>
										<el-button :disabled="!scope.row.inspectUrl"
											@click="accountClick(scope.row.inspectUrl)" type="text" size="small">查看
										</el-button>
									</div>
								</template>
							</el-table-column> -->
            </el-table>
            <div style="float: right; margin: 20px" v-if="orderStatus != 2">
              <button class="hy-btn" @click="receivingVisible = true">
                发起结算
              </button>
            </div>

            <div class="receiving"></div>
          </div>
        </div>
      </el-tab-pane>
      <!-- 开票 -->
      <el-tab-pane
        label="开票管理"
        name="second"
        :disabled="
          secondDisabled ||
          (contrac.signatureStatus == null
            ? contrac.contractStatus != 3
            : contrac.signatureStatus != 3)
        "
      >
        <div class="first">
          <particulars></particulars>
        </div>
        <div class="content" v-if="isPublisher == 'false'">
          <div class="uploading">
            <div class="uploading_all">
              <div class="shangchuang"></div>
              <div class="unpload">开票</div>
            </div>
            <div
              class="invoice hy-btn"
              @click="sponsorClick"
              v-if="orderStatus != 2"
            >
              发起开票
            </div>
          </div>
          <div style="margin-top: 30px">
            <el-table :data="tableData" border style="width: 100%">
              <el-table-column
                prop="createTime"
                align="center"
                label="开票时间"
              >
              </el-table-column>
              <el-table-column
                prop="invoiceCount"
                align="center"
                label="开票数量"
              >
              </el-table-column>
              <el-table-column
                prop="invoiceAmount"
                align="center"
                label="开票金额"
              >
              </el-table-column>
              <el-table-column
                fixed="right"
                align="center"
                label="发票单据"
                width="100"
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.invoiceUrlList">
                    <el-button
                      @click="accountClickList(scope.row)"
                      type="text"
                      size="small"
                    >
                      查看</el-button
                    >
                  </span>
                  <span v-else> 无图片信息 </span>
                </template>
              </el-table-column>
              <el-table-column
                fixed="right"
                align="center"
                label="操作"
                width="100"
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.confirmStatus != 1">
                    <el-button type="text" size="small">未确认</el-button>
                  </span>
                  <span v-else>
                    <el-button type="text" size="small">已确认</el-button>
                  </span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="content" v-if="isPublisher == 'true'">
          <div class="uploading">
            <div class="uploading_all">
              <div class="shangchuang"></div>
              <div class="unpload">开票</div>
            </div>
          </div>
          <div style="margin-top: 30px">
            <el-table :data="tableData" border style="width: 100%">
              <el-table-column
                prop="createTime"
                align="center"
                label="开票时间"
              >
              </el-table-column>
              <el-table-column
                prop="invoiceCount"
                align="center"
                label="开票数量"
              >
              </el-table-column>
              <el-table-column
                prop="invoiceAmount"
                align="center"
                label="开票金额"
              >
              </el-table-column>
              <el-table-column
                fixed="right"
                align="center"
                label="发票单据"
                width="100"
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.invoiceUrlList">
                    <el-button
                      @click="accountClickList(scope.row)"
                      type="text"
                      size="small"
                    >
                      查看</el-button
                    >
                  </span>
                  <span v-else> 无图片信息 </span>
                </template>
              </el-table-column>
              <el-table-column
                fixed="right"
                align="center"
                label="操作"
                width="100"
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.confirmStatus != 1">
                    <el-button
                      @click="noteconfirm(scope.row)"
                      type="text"
                      size="small"
                      >收票确认
                    </el-button>
                  </span>
                  <span v-else>
                    <el-button type="text" size="small">已确认</el-button>
                  </span>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <!-- <div class="receiving">
						<div class="sign hy-btn" @click="confirmClick">合同整体已完成</div>
					</div> -->
        </div>
      </el-tab-pane>
    </el-tabs>
    <!-- 签收货物 -->
    <div>
      <el-dialog
        title=""
        width="500px"
        :visible.sync="receivingVisible"
        @close="closeChan"
      >
        <el-form
          :model="receivingForm"
          ref="receivingForm"
          :rules="receivingFormRule"
        >
          <el-form-item
            label="结算数量"
            prop="signCount"
            :label-width="formLabelWidth"
          >
            <el-input
              v-model="receivingForm.signCount"
              autocomplete="off"
              @input="receivingFormSignCount('signCount')"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="结算单据"
            prop="invoiceUrl"
            :label-width="formLabelWidth"
          >
            <el-row>
              <el-col :span="8">
                <el-upload
                  class="upload-demo"
                  action="#"
                  :http-request="addinvoiceUrlUrl"
                  accept=".bmp,.jpg,.png,.jpeg,.pdf"
                  multiple
                  :show-file-list="false"
                >
                  <el-button size="small" type="primary">点击上传</el-button>
                </el-upload>
              </el-col>
              <el-col :span="16">
                <el-button
                  class="el-button el-button--small"
                  @click="examinecontract(receivingForm.invoiceUrl)"
                  v-if="
                    receivingForm.invoiceUrl &&
                    (receivingForm.invoiceUrl.slice(-4) == '.pdf' ||
                      receivingForm.invoiceUrl.slice(-4) == '.PDF')
                  "
                >
                  点击查看PDF文件<br /><br />{{
                    receivingForm.invoiceUrl
                  }}</el-button
                >
                <el-image
                  v-else-if="receivingForm.invoiceUrl"
                  style="width: 100px"
                  :src="fileBaseUrl + receivingForm.invoiceUrl"
                  :preview-src-list="[fileBaseUrl + receivingForm.invoiceUrl]"
                ></el-image>
              </el-col>
            </el-row>
          </el-form-item>
          <!-- <el-form-item label="煤质检验单" :label-width="formLabelWidth">
						<el-row>
							<el-col :span="8">
								<el-upload class="upload-demo" action="#" :http-request="addinspectUrlUrl"
									accept=".bmp,.jpg,.png,.jpeg,.pdf" :show-file-list="false" multiple>
									<el-button size="small" type="primary">点击上传</el-button>
								</el-upload>
							</el-col>
							<el-col :span="16">
								<el-button class="el-button el-button--small"
									@click="examinecontract(receivingForm.inspectUrl)"
									v-if="receivingForm.inspectUrl && ( receivingForm.inspectUrl.slice(-4) == '.pdf' || receivingForm.inspectUrl.slice(-4) == '.PDF' )">
									点击查看PDF文件</br></br>{{ receivingForm.inspectUrl }}</el-button>
								<el-image v-else-if="receivingForm.inspectUrl" style="width: 100px"
									:src="fileBaseUrl + receivingForm.inspectUrl"
									:preview-src-list="[fileBaseUrl + receivingForm.inspectUrl]"></el-image>
							</el-col>
						</el-row>
					</el-form-item> -->
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="receivingVisible = false">取 消</el-button>
          <el-button type="primary" @click="receivingClick">发起结算</el-button>
        </div>
      </el-dialog>
    </div>
    <!-- 选择合同模板 -->
    <div>
      <el-dialog title="选择合同模板" :visible.sync="dialogTableVisible">
        <el-table
          :data="gridData"
          border
          ref="tb"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" v-model="radio" width="55">
          </el-table-column>
          <el-table-column
            property="contractNum"
            label="合同编号"
            align="center"
          ></el-table-column>
          <el-table-column
            property="contractName"
            label="合同名称"
            align="center"
          ></el-table-column>
          <el-table-column
            property="createTime"
            align="center"
            label="提交时间"
          ></el-table-column>
          <el-table-column align="center" label="操作">
            <template slot-scope="scope">
              <el-button
                @click="examineClick(scope.row.contractUrl)"
                type="text"
                size="small"
                >查看
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogTableVisible = false">取 消</el-button>
          <el-button type="primary" @click="CurrentRowClick()">确 定</el-button>
        </span>
      </el-dialog>
    </div>
    <!--  -->
    <div>
      <el-dialog
        title="完善合同"
        :visible.sync="dialogTableVisibles"
        width="800px"
        custom-class="onlineInforules"
      >
        <el-form
          label-width="150px"
          :inline="true"
          :rules="onlineInforules"
          ref="onlineInfo"
          :model="onlineInfo"
        >
          <div>
            <el-form-item label="合同编号" prop="contractNo">
              <el-input
                v-model="onlineInfo.contractNo"
                placeholder="请输入合同编号"
              ></el-input>
            </el-form-item>
          </div>

          <el-form-item label="甲方" prop="supplier">
            <el-input
              v-model="onlineInfo.supplier"
              placeholder="请输入甲方"
            ></el-input>
          </el-form-item>
          <el-form-item label="乙方" prop="demander">
            <el-input
              v-model="onlineInfo.demander"
              placeholder="请输入乙方"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="甲方电子邮箱"
            prop="supplierEmail"
            :rules="{
              required: true,
              message: '请输入甲方电子邮箱',
              trigger: 'blur',
            }"
          >
            <el-input
              v-model="onlineInfo.supplierEmail"
              placeholder="请输入甲方电子邮箱"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="乙方电子邮箱"
            prop="demanderEmail"
            :rules="{
              required: true,
              message: '请输入乙方电子邮箱',
              trigger: 'blur',
            }"
          >
            <el-input
              v-model="onlineInfo.demanderEmail"
              placeholder="请输入乙方电子邮箱"
            ></el-input>
          </el-form-item>

          <el-form-item
            label="甲方开户银行"
            prop="supplierDepositBank"
            :rules="{
              required: true,
              message: '请输入甲方开户银行',
              trigger: 'blur',
            }"
          >
            <el-input
              v-model="onlineInfo.supplierDepositBank"
              placeholder="请输入甲方开户银行"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="乙方开户银行"
            prop="demanderDepositBank"
            :rules="{
              required: true,
              message: '请输入乙方开户银行',
              trigger: 'blur',
            }"
          >
            <el-input
              v-model="onlineInfo.demanderDepositBank"
              placeholder="请输入乙方开户银行"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="甲方银行账号"
            prop="supplierAccountNumber"
            :rules="{
              required: true,
              message: '请输入甲方开户银行账号',
              trigger: 'blur',
            }"
          >
            <el-input
              v-model="onlineInfo.supplierAccountNumber"
              placeholder="请输入甲方开户银行账号"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="乙方银行账号"
            prop="demanderAccountNumber"
            :rules="{
              required: true,
              message: '请输入乙方开户银行账号',
              trigger: 'blur',
            }"
          >
            <el-input
              v-model="onlineInfo.demanderAccountNumber"
              placeholder="请输入乙方开户银行账号"
            ></el-input>
          </el-form-item>

          <el-form-item
            label="甲方地址"
            prop="supplierCompanyAddress"
            :rules="{
              required: true,
              message: '请输入甲方地址',
              trigger: 'blur',
            }"
          >
            <el-input
              v-model="onlineInfo.supplierCompanyAddress"
              placeholder="请输入甲方地址"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="乙方地址"
            prop="demanderCompanyAddress"
            :rules="{
              required: true,
              message: '请输入乙方地址',
              trigger: 'blur',
            }"
          >
            <el-input
              v-model="onlineInfo.demanderCompanyAddress"
              placeholder="请输入乙方地址"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="甲方联系电话"
            prop="supplierPhone"
            :rules="{
              required: true,
              message: '请输入甲方联系电话',
              trigger: 'blur',
            }"
          >
            <el-input
              v-model="onlineInfo.supplierPhone"
              placeholder="请输入甲方联系电话"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="乙方联系电话"
            prop="demanderPhone"
            :rules="{
              required: true,
              message: '请输入乙方联系电话',
              trigger: 'blur',
            }"
          >
            <el-input
              v-model="onlineInfo.demanderPhone"
              placeholder="请输入乙方联系电话"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="甲方纳税人识别号"
            prop="supplierBusinessLicense"
            :rules="{
              required: true,
              message: '请输入甲方纳税人识别号',
              trigger: 'blur',
            }"
          >
            <el-input
              v-model="onlineInfo.supplierBusinessLicense"
              placeholder="请输入甲方纳税人识别号"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="乙方纳税人识别号"
            prop="demanderBusinessLicense"
            :rules="{
              required: true,
              message: '请输入乙方纳税人识别号',
              trigger: 'blur',
            }"
          >
            <el-input
              v-model="onlineInfo.demanderBusinessLicense"
              placeholder="请输入乙方纳税人识别号"
            ></el-input>
          </el-form-item>

          <el-form-item
            label="出发地"
            prop="startAddress"
            :rules="{
              required: true,
              message: '请输入出发地',
              trigger: 'blur',
            }"
          >
            <el-input
              v-model="onlineInfo.startAddress"
              placeholder="请输入出发地"
            ></el-input>
          </el-form-item>

          <el-form-item
            label="目的地"
            prop="endAddress"
            :rules="{
              required: true,
              message: '请输入目的地',
              trigger: 'blur',
            }"
          >
            <el-input
              v-model="onlineInfo.endAddress"
              placeholder="请输入目的地"
            ></el-input>
          </el-form-item>

          <el-form-item
            label="中标单价(元/吨)(含9%增值税)"
            prop="winPrice"
            :rules="{
              required: true,
              message: '请输入中标单价',
              trigger: ['blur', 'change'],
            }"
          >
            <el-input-number
              placeholder="请输入中标单价"
              v-model="onlineInfo.winPrice"
              :min="0"
              :controls="false"
            ></el-input-number>
          </el-form-item>

          <el-form-item
            label="中标运量(万吨)"
            prop="winFreightVolume"
            :rules="{
              required: true,
              message: '请输入中标运量',
              trigger: ['blur', 'change'],
            }"
          >
            <el-input-number
              placeholder="请输入中标运量"
              v-model="onlineInfo.winFreightVolume"
              :min="0"
              :controls="false"
            ></el-input-number>
          </el-form-item>
          <div
            class="FreightVolume"
            v-for="(item, index) in onlineInfo.monthVolumeList"
            :key="item.key"
          >
            <div>
              <div class="onlineInforules-title" style="padding-left: 50px">
                月度运量{{ index + 1 }}
                <i
                  class="el-icon-delete"
                  v-if="onlineInfo.monthVolumeList.length > 1"
                  @click="deleteFreightVolume(item.key)"
                ></i>
                <i
                  class="el-icon-plus"
                  v-if="
                    item.key ==
                    onlineInfo.monthVolumeList[
                      onlineInfo.monthVolumeList.length - 1
                    ].key
                  "
                  @click="addFreightVolume"
                ></i>
              </div>

              <el-form-item
                label="拉运时间"
                :prop="'monthVolumeList.' + index + '.date'"
                :rules="{
                  required: true,
                  message: '请选择拉运时间',
                  trigger: 'change',
                }"
              >
                <el-date-picker
                  v-model="onlineInfo.monthVolumeList[index].date"
                  value-format="yyyy-MM"
                  format="yyyy-MM"
                  type="month"
                  placeholder="请选择拉运时间"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item
                label="拉运量(万吨)"
                :prop="'monthVolumeList.' + index + '.volume'"
                :rules="{
                  required: true,
                  message: '请输入拉运量',
                  trigger: 'blur',
                }"
              >
                <el-input-number
                  v-model="onlineInfo.monthVolumeList[index].volume"
                  :min="0"
                  :controls="false"
                  placeholder="请输入拉运量"
                ></el-input-number>
              </el-form-item>
            </div>
          </div>
          <el-form-item
            label="合同期限"
            prop="contractTime"
            :rules="{
              required: true,
              message: '请选择合同期限',
              trigger: ['blur', 'change'],
            }"
          >
            <el-date-picker
              v-model="onlineInfo.contractTime"
              type="daterange"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </el-form-item>
          <div>
            <div class="onlineInforules-title" style="padding-left: 50px">
              损耗责任:
            </div>
            <div class="onlineInforules-text" style="padding-left: 80px">
              乙方承担从出发地起至各到达货场超过
              <el-form-item
                label=""
                prop="allowloss"
                :rules="{
                  required: true,
                  message: '请输入损耗比率',
                  trigger: ['blur', 'change'],
                }"
              >
                <el-input-number
                  v-model="onlineInfo.allowloss"
                  :min="0"
                  size="mini"
                  :controls="false"
                ></el-input-number> </el-form-item
              >‰以上的运输出入库损耗责任，超过部分的量以甲方核算为准。
            </div>
          </div>
          <div>
            <div class="onlineInforules-title" style="padding-left: 50px">
              履约保证金:
            </div>
            <div class="onlineInforules-text" style="padding-left: 80px">
              乙方中标后2个工作日内向甲方缴纳
              <el-form-item
                label=""
                prop="bondAmount"
                :rules="{
                  required: true,
                  message: '请输入保证金金额',
                  trigger: ['blur', 'change'],
                }"
              >
                <el-input-number
                  v-model="onlineInfo.bondAmount"
                  :min="0"
                  size="mini"
                  :controls="false"
                ></el-input-number> </el-form-item
              >万元 履约保证金。
            </div>
          </div>
          <div>
            <div class="onlineInforules-title" style="padding-left: 50px">
              增值税发票违约金:
            </div>
            <div class="onlineInforules-text" style="padding-left: 80px">
              乙方提供的增值税发票必须真实、合法，若因乙方提供的增值税票造成甲方损失（包括但不限于罚款、滞纳金），甲方有权从乙方运费或履约保证金中扣除，不足部分甲方有权向乙方追偿，并且由乙方承担甲方损失总额
              <el-form-item
                label=""
                prop="totalLossRate"
                :rules="{
                  required: true,
                  message: '请输入损失总额比率',
                  trigger: ['blur', 'change'],
                }"
              >
                <el-input-number
                  v-model="onlineInfo.totalLossRate"
                  :min="0"
                  size="mini"
                  :controls="false"
                ></el-input-number> </el-form-item
              >%的违约金。
            </div>
          </div>
          <div>
            <div class="onlineInforules-title" style="padding-left: 50px">
              煤炭质量违约金:
            </div>
            <div class="onlineInforules-text" style="padding-left: 80px">
              乙方承运煤炭过程中及收货方煤场仓库时的煤炭质量，若出现乙方对甲方的煤炭进行更换、掺假、偷盗、加水等情况，乙方自愿承担全部赔偿责任和违约责任，违约金为本批次货值（本批次货值以甲方认定的数额为准）的
              <el-form-item
                label=""
                prop="valueRate"
                :rules="{
                  required: true,
                  message: '请输入货值比率',
                  trigger: ['blur', 'change'],
                }"
              >
                <el-input-number
                  v-model="onlineInfo.valueRate"
                  :min="0"
                  size="mini"
                  :controls="false"
                ></el-input-number> </el-form-item
              >%金额。
            </div>
          </div>
          <div>
            <div class="onlineInforules-title" style="padding-left: 50px">
              运量考核:
            </div>
            <div class="onlineInforules-text" style="padding-left: 80px">
              当乙方未按期完成月度考核运量<90%时，按未完成月度考核运量对应运费的
              <el-form-item
                label=""
                prop="freightRate"
                :rules="{
                  required: true,
                  message: '请输入对应运费比率',
                  trigger: ['blur', 'change'],
                }"
              >
                <el-input-number
                  v-model="onlineInfo.freightRate"
                  :min="0"
                  size="mini"
                  :controls="false"
                >
                </el-input-number>
              </el-form-item>
              %承担违约金。
            </div>
          </div>
        </el-form>

        <div style="text-align: center">
          <el-button type="primary" @click="onSubmit">立即创建</el-button>
          <el-button @click="dialogTableVisibles = false">取消</el-button>
        </div>
      </el-dialog>
    </div>
    <!-- 发起开票 -->
    <div>
      <el-dialog title="开票" width="500px" :visible.sync="dialogFormVisible">
        <el-form :model="form" ref="invoiceForm" :rules="formRules">
          <el-form-item
            label="开票数量："
            :label-width="formLabelWidth"
            prop="invoiceCount"
          >
            <el-input
              placeholder="请输入开票数量"
              v-model.trim="form.invoiceCount"
              autocomplete="off"
              maxlength="20"
              style="width: 120px"
              @input="receivingFormSignCount('invoiceCount')"
            >
            </el-input>
            <span style="margin-left: 20px">吨</span>
          </el-form-item>
          <el-form-item
            label="开票金额："
            :label-width="formLabelWidth"
            prop="invoiceAmount"
          >
            <el-input
              placeholder="请输入开票金额"
              v-model.trim="form.invoiceAmount"
              autocomplete="off"
              maxlength="20"
              @input="receivingFormSignCount('invoiceAmount')"
              style="width: 120px"
            >
            </el-input
            ><span style="margin-left: 20px">元</span>
          </el-form-item>
          <el-form-item
            label="发票单据："
            :label-width="formLabelWidth"
            prop="invoiceUrl"
          >
            <el-row>
              <el-col :span="8">
                <el-upload
                  class="upload-demo"
                  action="#"
                  accept=".bmp,.jpg,.png,.jpeg,.pdf"
                  :http-request="addsettleUrl"
                  :show-file-list="false"
                  multiple
                >
                  <el-button size="small" type="primary">点击上传</el-button>
                </el-upload>
              </el-col>

              <el-col :span="16">
                <el-button
                  class="el-button el-button--small"
                  @click="examinecontract(form.invoiceUrl)"
                  v-if="
                    form.invoiceUrl &&
                    (form.invoiceUrl.slice(-4) == '.pdf' ||
                      form.invoiceUrl.slice(-4) == '.PDF')
                  "
                >
                  点击查看PDF文件<br /><br />{{ form.invoiceUrl }}</el-button
                >
                <el-image
                  v-else-if="form.invoiceUrl"
                  style="width: 100px"
                  :src="fileBaseUrl + form.invoiceUrl"
                  :preview-src-list="[fileBaseUrl + form.invoiceUrl]"
                ></el-image>
              </el-col>
            </el-row>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="addinvoice">确 定</el-button>
        </div>
      </el-dialog>
    </div>
    <el-dialog title="选择服务商" :visible.sync="productlist">
      <div>
        <div
          class="productItem"
          v-for="(item, index) in tableList"
          :key="index"
        >
          <div style="display: flex">
            <div class="produitem_img">
              <img
                :src="baseUrl + 'third/third/ossGetFile/' + item.mainGraph"
                alt=""
              />
            </div>
            <div style="margin-left: 20px; color: #40474e; font-weight: 500">
              <div style="font-size: 20px">{{ item.name }}</div>
              <div style="display: flex; margin-top: 13px; font-size: 14px">
                <div>服务类别：{{ item.serviceType }}</div>
                <div style="margin: 0 10px">|</div>
                <div v-if="item.price != null">
                  单价：{{ item.price }}{{ item.unit }}/吨
                </div>
                <div v-else>单价：面议</div>
              </div>
              <div style="margin-top: 20px; font-size: 12px; color: #787b80">
                {{ item.createTime }}
              </div>
            </div>
          </div>

          <div>
            <div class="onsale" style="">销售中</div>
            <el-button
              type="primary"
              style="margin-top: 40px"
              @click="transmitClick(item)"
              >确认发送</el-button
            >
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 服务商运输 -->
    <div>
      <el-dialog
        title="服务商运输"
        center
        width="1000px"
        :visible.sync="transportations"
      >
        <el-form :model="transports" ref="transports" :rules="transportRules">
          <div style="display: flex">
            <el-form-item
              label="请输入运输数量："
              prop="trafficCount"
              :label-width="formLabelWidth"
            >
              <el-input
                placeholder="请输入运输数量(吨)"
                maxlength="5"
                v-model="transports.trafficCount"
                autocomplete="off"
                :max="data.orderCount"
                @input="receivingFormSignCountss"
              >
              </el-input>
            </el-form-item>
            <el-form-item
              label="请输入运输单价："
              prop="trafficPrice"
              label-width="190px"
            >
              <el-input
                placeholder="请输入运输单价(元/吨)"
                maxlength="5"
                v-model="transports.trafficPrice"
                autocomplete="off"
                :max="data.orderCount"
              >
              </el-input>
            </el-form-item>
          </div>
          <div style="display: flex">
            <el-form-item
              label="请选择商品名称："
              prop="goodsName"
              :label-width="formLabelWidth"
            >
              <el-cascader
                v-model="transports.goodsName"
                :options="nameList"
                :props="{ value: 'id', label: 'dictName' }"
                placeholder="请选择商品名称"
                @change="goodsChange"
              ></el-cascader>
            </el-form-item>
            <el-form-item
              label="请选择付款类型："
              prop="payWay"
              label-width="190px"
            >
              <el-select
                v-model="transports.payWay"
                placeholder="请选择付款类型"
              >
                <el-option label="先货后款" value="1"></el-option>
                <el-option label="先款后货" value="2"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div style="display: flex">
            <el-form-item
              label="请输入联系人："
              prop="contacts"
              :label-width="formLabelWidth"
            >
              <el-input
                v-model="transports.contacts"
                placeholder="请输入联系人"
                type="text"
                onkeyup="value=value.replace(/[\d]/g,'') "
                maxlength="20"
                minlength="2"
                onbeforepaste="clipboardData.setData('text',clipboardData.getData('text').replace(/[\d]/g,''))"
                autocomplete="off"
                @blur="transportContactss"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="请输入联系方式："
              prop="contactInfo"
              label-width="190px"
            >
              <el-input
                v-model="transports.contactInfo"
                onkeyup="value=value.replace(/[^\d]/g,'')"
                placeholder="请输入联系方式"
                autocomplete="off"
                @blur="transportContactInfos"
              >
              </el-input>
            </el-form-item>
          </div>
          <div style="display: flex">
            <el-form-item
              label="请选择起始地："
              prop="startPlace"
              :label-width="formLabelWidth"
            >
              <el-cascader
                v-model="transports.startPlace"
                :options="citys"
                @change="selectChanges('startPlace')"
                placeholder="请输入起始地"
                :props="{
                  value: 'label',
                  label: 'label',
                }"
              ></el-cascader>
            </el-form-item>
            <el-form-item
              label="请输入起始地详细地址："
              prop="startAddress"
              label-width="190px"
            >
              <el-input
                v-model="transports.startAddress"
                placeholder="请输入起始地详细地址"
                type="text"
                onkeyup="value=value.replace(/[\d]/g,'') "
                onbeforepaste="clipboardData.setData('text',clipboardData.getData('text').replace(/[\d]/g,''))"
                autocomplete="off"
                maxlength="100"
              ></el-input>
            </el-form-item>
          </div>
          <div style="display: flex">
            <el-form-item
              label="请选择目的地："
              prop="destPlace"
              :label-width="formLabelWidth"
            >
              <!-- <el-input v-model="transports.destPlace" type="text" placeholder="请输入目的地"
								onkeyup="value=value.replace(/[\d]/g,'') "
								onbeforepaste="clipboardData.setData('text',clipboardData.getData('text').replace(/[\d]/g,''))"
								autocomplete="off" @blur="transportDestPlace"></el-input> -->
              <el-cascader
                v-model="transports.destPlace"
                :options="citys"
                @change="selectChanges('destPlace')"
                placeholder="请输入目的地"
                :props="{
                  value: 'label',
                  label: 'label',
                }"
              ></el-cascader>
            </el-form-item>
            <el-form-item
              label="请输入目的地详细地址："
              prop="endAddress"
              label-width="190px"
            >
              <el-input
                v-model="transports.endAddress"
                placeholder="请输入目的地详细地址"
                type="text"
                onkeyup="value=value.replace(/[\d]/g,'') "
                onbeforepaste="clipboardData.setData('text',clipboardData.getData('text').replace(/[\d]/g,''))"
                autocomplete="off"
                maxlength="100"
              ></el-input>
            </el-form-item>
          </div>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="transportations = false">取 消</el-button>
          <el-button type="primary" @click="carRiages">下一步</el-button>
        </div>
      </el-dialog>
    </div>
    <!-- 发起运输 -->
    <div>
      <el-dialog
        title="发起运输"
        center
        :visible.sync="transportation"
        width="535px"
        @close="closeChang"
      >
        <el-form :model="transport" ref="transport" :rules="transportRule">
          <div>
            <el-form-item
              label="运输数量："
              prop="trafficCount"
              :label-width="formLabelWidth"
            >
              <el-input
                placeholder="请输入运输数量(吨)"
                maxlength="7"
                v-model="transport.trafficCount"
                autocomplete="off"
                :max="data.orderCount"
                onkeyup="value=value.replace(/[^\d\.]/g,'')"
                @input="receivingFormSignCount('trafficCount')"
              >
              </el-input>
            </el-form-item>
            <el-form-item
              label="运输方式："
              prop="trafficWay"
              :label-width="formLabelWidth"
            >
              <el-select v-model="transport.trafficWay" placeholder="请选择">
                <el-option label="公路运输" value="1"></el-option>
                <el-option label="铁路运输" value="2"></el-option>
                <el-option label="公铁联运" value="3"></el-option>
                <!-- <el-option label="海运" value="4"></el-option> -->
              </el-select>
            </el-form-item>
            <el-form-item
              label="请选择起始地："
              prop="startPlace"
              :label-width="formLabelWidth"
            >
              <el-cascader
                v-model="transport.startPlace"
                :options="citys"
                @change="selectChange('startPlace')"
                placeholder="请选择起始地"
                :props="{
                  value: 'label',
                  label: 'label',
                }"
              ></el-cascader>
            </el-form-item>
            <el-form-item
              label="请输入目的地："
              prop="destPlace"
              :label-width="formLabelWidth"
            >
              <el-cascader
                v-model="transport.destPlace"
                :options="citys"
                @change="selectChange('destPlace')"
                placeholder="请输入目的地"
                :props="{
                  value: 'label',
                  label: 'label',
                }"
              ></el-cascader>
            </el-form-item>
          </div>
          <div>
            <el-form-item
              label="请输入联系人："
              prop="contacts"
              :label-width="formLabelWidth"
            >
              <el-input
                v-model="transport.contacts"
                placeholder="请输入联系人"
                type="text"
                onkeyup="value=value.replace(/[\d]/g,'') "
                maxlength="20"
                minlength="2"
                onbeforepaste="clipboardData.setData('text',clipboardData.getData('text').replace(/[\d]/g,''))"
                autocomplete="off"
                @blur="transportContacts"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="联系方式："
              prop="contactInfo"
              :label-width="formLabelWidth"
            >
              <el-input
                v-model="transport.contactInfo"
                onkeyup="value=value.replace(/[^\d]/g,'')"
                placeholder="请输入联系方式"
                autocomplete="off"
                @blur="transportContactInfo"
                maxlength="11"
              >
              </el-input>
            </el-form-item>
          </div>
          <div>
            <el-form-item
              label="运输凭证："
              prop="fileIds"
              :label-width="formLabelWidth"
            >
              <div style="display: flex">
                <el-upload
                  class="upload-demo"
                  action="#"
                  :http-request="addinspectUrlUrls"
                  accept=".bmp,.jpg,.png,.jpeg,.pdf"
                  :limit="1000"
                  :file-list="transportfileList"
                  :on-exceed="handleExceedel"
                  :on-change="handleChangerel"
                  multiple
                  :show-file-list="false"
                >
                  <el-button type="primary">点击上传</el-button>
                </el-upload>
                <el-button
                  class="img-box"
                  style="margin-left: 20px"
                  @click="inspectprevie"
                  v-if="ransportviews.length > 0"
                  >点击查看所有图片<i
                    @click.stop="delImg"
                    class="del-img el-icon-delete-solid"
                  ></i
                ></el-button>
              </div>
            </el-form-item>
          </div>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="transportation = false">取 消</el-button>
          <el-button type="primary" @click="carRiage">提交</el-button>
        </div>
      </el-dialog>
    </div>
    <!-- 预览铁路运输发票 -->
    <div>
      <el-dialog title="预览" width="30%" :visible.sync="receivingVisi">
        <div class="block">
          <el-carousel trigger="click" :autoplay="false" min-height="300px">
            <el-carousel-item
              v-for="(item, index) in ransportviews"
              :key="index"
              class="image_item"
              style="
                display: flex;
                align-items: center;
                justify-content: center;
              "
            >
              <el-button
                @click="examinecontract(item)"
                v-if="
                  (item && item.slice(-4) == '.pdf') ||
                  (item && item.slice(-4) == '.PDF')
                "
              >
                点击查看PDF文件<br /><br /><br />{{ item }}</el-button
              >
              <el-image
                v-else
                :src="baseUrl + 'third/third/ossGetFile/' + item"
                :preview-src-list="[baseUrl + 'third/third/ossGetFile/' + item]"
              ></el-image>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div style="display: flex; justify-content: center; margin-top: 20px">
          <el-button type="primary" @click="receivingVisi = false"
            >确定</el-button
          >
        </div>
      </el-dialog>
    </div>
    <!-- 支付 -->
    <el-dialog
      :title="dialogTitle"
      width="30%"
      :visible.sync="dialogofflineForm"
      @close="CloseChange"
    >
      <el-form :model="offlineForm" ref="offlineForm" :rules="offlineFormRule">
        <!-- <el-form-item label="总数量:" :label-width="formLabelWidth"> 
					{{ data.orderCount }}吨
				</el-form-item> -->
        <el-form-item
          label="支付数量:"
          prop="payCount"
          :label-width="formLabelWidth"
        >
          <el-input
            :placeholder="'请输入数量(吨)'"
            maxlength="20"
            v-model.trim="offlineForm.payCount"
            autocomplete="off"
            @input="formatFloat('payCount')"
          ></el-input>
        </el-form-item>

        <el-form-item
          label="中标价格:"
          prop="payPrice"
          maxlength="5"
          :label-width="formLabelWidth"
        >
          <el-input
            v-model.trim="offlineForm.payPrice"
            autocomplete="off"
            onkeyup="value=value.replace(/[^\d]/g,'')"
            maxlength="5"
            placeholder="中标价格"
            disabled
            @blur="offlineFormPayPrice"
            @input="formatFloat('payPrice')"
          ></el-input>
        </el-form-item>
        <!-- 
				<el-form-item label="单价:" prop="payPrice" maxlength="5" :label-width="formLabelWidth" v-else>
					<el-input v-model.trim="offlineForm.payPrice" autocomplete="off"
						onkeyup="value=value.replace(/[^\d]/g,'')" maxlength="5" placeholder="请输入单价(元/吨)"
						:disabled="isoffline" @blur="offlineFormPayPrice" @input="formatFloat('payPrice')"></el-input>
				</el-form-item> -->
        <el-form-item
          label="支付方式:"
          prop="bankType"
          :label-width="formLabelWidth"
          v-if="dialogTitle == '线上支付'"
        >
          <el-radio
            v-for="(item, index) in balances"
            :key="index"
            v-model="offlineForm.bankType"
            :label="item.bankType"
            :disabled="item.ifAsh"
          >
            <span v-if="item.bankType == 0"
              >中信账户余额(￥{{ item.balance }})</span
            >
            <span v-if="item.bankType == 1"
              >中金账户余额(￥{{ item.balance }})</span
            >
          </el-radio>
        </el-form-item>
        <el-form-item
          label="收款账户信息:"
          :label-width="formLabelWidth"
          v-if="dialogTitle == '线下支付'"
        >
          <!-- <el-input v-model="offlineForm.payCount" autocomplete="off"></el-input> -->
          <div style="display: flex" v-if="enterprise">
            <div class="copyinput">收款账号:{{ enterprise.accountNumber }}</div>
            <div
              style="margin-left: 10px; color: #0479fc"
              @click="copyFn(enterprise.accountNumber)"
            >
              复制
            </div>
          </div>
          <div style="display: flex" v-if="enterprise">
            <div>收款户名:</div>
            <div>{{ enterprise.accountName }}</div>
          </div>
          <div style="display: flex" v-if="enterprise">
            <div>收款银行:</div>
            <div>{{ enterprise.accountBank }}</div>
          </div>
        </el-form-item>
      </el-form>
      <div style="font-size: 20px; margin-left: 40px">
        需支付金额 ：<span style="color: red">￥</span>
        <span style="color: red; font-size: 30px">{{ ficenum }}</span>
        元
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogofflineForm = false">取 消</el-button>
        <el-button type="primary" @click="flineFormClicks(headertype)"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <!-- 预览 -->
    <el-dialog title="预览" :visible.sync="preview" width="30%" center>
      <div class="previewIm">
        <!-- <img :src="previewImage" alt="" /> -->
        <el-image
          v-if="previewImage"
          :src="previewImage"
          :preview-src-list="[previewImage]"
        >
        </el-image>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="preview = false">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 签章列表 -->
    <el-dialog
      title="在线签章"
      :visible.sync="dialogopreview"
      :close-on-click-modal="false"
      width="1100px"
      class="sign"
      :show-close="false"
      center
    >
      <div class="iframe">
        <onlineSignature
          v-if="dialogopreview"
          :Softsign="Softsign"
          :signaturePwd="signaturePwd"
          :signatureName="signatureName"
          :documentId="documentId"
          @closeSign="closeDialogopreview"
        >
        </onlineSignature>
      </div>
    </el-dialog>
    <!-- 签收查看票据 -->
    <el-dialog title="预览" :visible.sync="dialogopreviews" width="40%" center>
      <div class="dialogopreviews_item">
        <div class="item_content">
          <div>煤炭检验单</div>
          <div v-if="thepreview.inspectUrl == ''">未上传</div>
          <div v-else>
            <el-image
              v-if="thepreview.inspectUrl"
              style="width: 200px"
              :src="baseUrl + 'third/third/ossGetFile/' + thepreview.inspectUrl"
              :preview-src-list="[
                baseUrl + 'third/third/ossGetFile/' + thepreview.inspectUrl,
              ]"
            >
            </el-image>
          </div>
        </div>
        <div class="item_content">
          <div>电厂收货单</div>
          <div
            v-if="thepreview.invoiceUrls && thepreview.invoiceUrls.length == 0"
          >
            未上传
          </div>
          <div v-else>
            <div style="width: 200px; height: 200px">
              <el-carousel trigger="click" :autoplay="false" height="150px">
                <el-carousel-item
                  v-for="(item, index) in thepreview.invoiceUrls"
                  :key="index"
                  class="image_item"
                >
                  <el-image
                    :src="
                      baseUrl + 'third/third/ossGetFile/' + item.storageName
                    "
                    :preview-src-list="[
                      baseUrl + 'third/third/ossGetFile/' + item.storageName,
                    ]"
                  ></el-image>
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>
        </div>
        <div class="item_content">
          <div>运输凭证</div>
          <div
            v-if="thepreview.receiptUrls && thepreview.receiptUrls.length == 0"
          >
            未上传
          </div>
          <div v-else>
            <div style="width: 200px; height: 200px">
              <el-carousel trigger="click" :autoplay="false" height="150px">
                <el-carousel-item
                  v-for="(item, index) in thepreview.receiptUrls"
                  :key="index"
                  class="image_item"
                >
                  <el-image
                    :src="
                      baseUrl + 'third/third/ossGetFile/' + item.storageName
                    "
                    :preview-src-list="[
                      baseUrl + 'third/third/ossGetFile/' + item.storageName,
                    ]"
                  ></el-image>
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <div>
      <el-dialog title="预览" width="30%" :visible.sync="receivingVisibles">
        <div class="block">
          <el-carousel trigger="click" :autoplay="false" height="300px">
            <el-carousel-item
              v-for="(item, index) in sunbmitpreview"
              :key="index"
              class="image_item"
            >
              <el-image
                :src="baseUrl + 'third/third/ossGetFile/' + item"
                :preview-src-list="[baseUrl + 'third/third/ossGetFile/' + item]"
              ></el-image>
            </el-carousel-item>
          </el-carousel>
        </div>
      </el-dialog>
    </div>
    <!-- 运输凭证预览 -->
    <div>
      <el-dialog title="预览" width="30%" :visible.sync="receivingVisibless">
        <div class="block">
          <el-carousel trigger="click" :autoplay="false" height="300px">
            <el-carousel-item
              v-for="(item, index) in sunbmitpreviews"
              :key="index"
              class="image_item"
            >
              <el-image
                :src="baseUrl + 'third/third/ossGetFile/' + item"
                :preview-src-list="[baseUrl + 'third/third/ossGetFile/' + item]"
              ></el-image>
            </el-carousel-item>
          </el-carousel>
        </div>
      </el-dialog>
    </div>
    <!-- 结算单据查看 -->
    <div>
      <el-dialog title="预览" width="30%" :visible.sync="istrafficDocUrls">
        <div class="block">
          <el-carousel trigger="click" :autoplay="false" height="300px">
            <el-carousel-item
              v-for="(item, index) in trafficDocUrlslist"
              :key="index"
              class="image_item"
              style="
                display: flex;
                align-items: center;
                justify-content: center;
              "
            >
              <el-button
                @click="examinecontract(item.storageName)"
                v-if="
                  item.storageName.slice(-4) == '.pdf' ||
                  item.storageName.slice(-4) == '.PDF'
                "
              >
                点击查看PDF文件<br /><br /><br />{{
                  item.storageName
                }}</el-button
              >
              <el-image
                v-else
                :src="baseUrl + 'third/third/ossGetFile/' + item.storageName"
                :preview-src-list="[
                  baseUrl + 'third/third/ossGetFile/' + item.storageName,
                ]"
              ></el-image>
            </el-carousel-item>
          </el-carousel>
        </div>
      </el-dialog>
    </div>
    <!-- 合同预览 -->
    <el-dialog title="合同预览" :visible.sync="isaccount" center>
      <div class="">
        <div
          v-for="(item, index) in contimgs"
          :key="index"
          style="
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 20px;
          "
        >
          <el-button
            @click="examinecontract(item.storageName)"
            v-if="
              item.storageName.slice(-4) == '.pdf' ||
              item.storageName.slice(-4) == '.PDF'
            "
          >
            点击查看PDF文件<br /><br /><br />{{ item.originalName }}
          </el-button>
          <el-button
            @click="examinecontract(item.storageName)"
            v-if="item.storageName.slice(-5) == '.docx'"
          >
            点击查看PDF文件<br /><br /><br />{{ item.originalName }}
          </el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title="发起结算"
      :visible.sync="receivingVisiblebel"
      width="40%"
      center
    >
      <div class="danju">
        <div class="danju_item">
          <span style="color: red">*</span> 结算单据：
        </div>
        <el-upload
          class="upload-demo"
          action="#"
          accept=".bmp,.jpg,.png,.jpeg"
          :http-request="addsettleUrlres"
          :show-file-list="false"
          multiple
        >
          <el-button size="small" type="primary">点击上传</el-button>
        </el-upload>
        <div style="margin-left: 30px">
          <el-image
            v-if="settleUrls"
            style="width: 100px"
            :src="fileBaseUrl + settleUrls"
            :preview-src-list="[fileBaseUrl + settleUrls]"
          >
          </el-image>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="receivingVisiblebel = false">取 消</el-button>
        <el-button type="primary" @click="carRiageres">提交</el-button>
      </div>
    </el-dialog>
    <signDownloadDrive ref="signDownloadDrive"></signDownloadDrive>
    <InputPasswordDialog
      :isShow="passWordIsShows"
      ref="InputPasswordDialog"
      title="请输入交易密码"
      @close="closeDialog('passWordIsShow')"
      @submit="submit"
    ></InputPasswordDialog>
    <!-- 企业名称获取 -->
    <el-dialog title="登录" :visible.sync="dialogVisibless" width="500px">
      <div>
        <el-form
          :model="numberValidateForms"
          :refs="numberValidateFormrules"
          ref="numberValidateForms"
          label-width="150px"
          class="demo-ruleForm"
        >
          <el-form-item
            label="企业名称"
            prop="name"
            :rules="[{ required: true, message: '企业名称不能为空' }]"
          >
            <el-input
              disabled
              v-model.number="numberValidateForms.name"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="统一信用代码"
            prop="card_id"
            :rules="[{ required: true, message: '统一信用代码不能为空' }]"
          >
            <el-input
              disabled
              v-model.number="numberValidateForms.card_id"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <div
            style="
              text-align: right;
              width: 80%;
              color: blue;
              cursor: pointer;
              margin: 20px 0;
            "
            @click="(dialogVisibless = false), (dialogVisibles = true)"
          >
            使用账号及密码获取
          </div>
          <el-form-item>
            <el-button type="primary" @click="submitForm('numberValidateForms')"
              >提交</el-button
            >
            <el-button @click="dialogVisibless = false">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <!-- 账号密码获取 -->
    <el-dialog
      title="登录"
      :visible.sync="dialogVisibles"
      :before-close="numberValidateFormclose"
      width="400px"
    >
      <div>
        <el-form
          :model="numberValidateForm"
          :rules="numberValidateFormrules"
          ref="numberValidateForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="账号" prop="account">
            <el-input
              v-model="numberValidateForm.account"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="sign_pwd">
            <el-input
              type="password"
              v-model="numberValidateForm.sign_pwd"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <div
            style="
              text-align: right;
              width: 88%;
              color: blue;
              cursor: pointer;
              margin: 20px 0;
            "
            @click="(dialogVisibless = true), numberValidateFormclose()"
          >
            使用其他方式获取
          </div>
          <el-form-item>
            <el-button type="primary" @click="submitForm('numberValidateForm')"
              >提交</el-button
            >
            <el-button @click="dialogVisibles = false">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="dialogVisibleClonse"
    >
      <span>请选择您的签章方式</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="mildSignature">软签获取</el-button>
        <el-button type="primary" @click="Toobtainan">ukey获取</el-button>
      </span>
    </el-dialog>

    <div>
      <el-dialog title="预览" width="30%" :visible.sync="invoiceUrlVis">
        <div
          class="block"
          v-for="(item, index) in form.invoiceUrlList"
          :key="index"
          style="display: flex; justify-content: center"
        >
          <el-button
            @click="examinecontract(item)"
            v-if="
              (item && item.slice(-4) == '.pdf') ||
              (item && item.slice(-4) == '.PDF')
            "
          >
            点击查看PDF文件<br /><br /><br />{{ item }}</el-button
          >
          <el-image
            v-else
            :src="baseUrl + 'third/third/ossGetFile/' + item"
            :preview-src-list="[baseUrl + 'third/third/ossGetFile/' + item]"
          ></el-image>
        </div>
        <div style="display: flex; justify-content: center; margin-top: 20px">
          <el-button type="primary" @click="invoiceUrlVisFlse">确定</el-button>
        </div>
      </el-dialog>
    </div>
    <iframe
      style="display: none"
      width="960px"
      height="100%"
      frameborder="0"
      :src="serverUrl + '/iCloudFrontLoader/pc#/'"
      id="topFrame"
      name="topFrame"
    ></iframe>
  </div>
</template>

<script>
import * as ESASignClient from "@/utils/signConfig/ESASignClient.js";
import particulars from "@/layout/particulars1.vue";
import onlineSignature from "./components/onlineSignature";
const { baseUrl } = require("@/config");
import { getAccountBalanceByParam } from "@/api/Accountopening";
import url from "@/api/url/hy_url";
import {
  ossUploadFiletw,
  ossUploadFile,
  saveContractUrl,
  invoicepage,
  paymentpage,
  trafficpage,
  signpage,
  addtrafficpage,
  addinvoice,
  detailByOrder,
  confirm,
  addpayment,
  payTradeOrder,
  addconfirm,
  addsign,
  confirmReceive,
  finish,
  updateVoucher,
  listTemplate,
  confirmInvoice,
  confirmDeliver,
  confirmSettle,
  getNewestInvoice,
  placeTransportOrderFromTrade,
  ossUploadFiletwo,
  getOnlineContractInfo,
  updateOnlineContractInfo,
  getOnlineContractTransportInfo,
  updateOnlineContractTransportInfo,
} from "@/api/public";
import { getCompanyAuthDetail, getContractOSSFile } from "@/api/userCenters.js";
import signBaseUrl from "@/config/signConfig";
import InputPasswordDialog from "./components/passwordInput.vue";
import md5 from "js-md5";
import citys from "@/assets/json/citys";
import { serviceGoodCollection } from "@/api/home";
import { reqCompanyAuthDetail, reqDictType } from "@/api/hy";
import { getLoginUrl, getContractState } from "@/api/userCenters.js";
import { listTbOrderDeatil } from "@/api/Logistics.js";
import * as types from "@/store/action-types";
import Vue from "vue";
import signDownloadDrive from "@/components/signDownloadDrive.vue";
let userId = Vue.ls.get(types.userId);
import { formatDate } from "../../utils/date";
export default {
  components: {
    particulars,
    onlineSignature,
    signDownloadDrive,
    InputPasswordDialog,
  },
  data() {
    return {
      balances: [],
      firstDisabled: false,
      threeDisabled: false,
      thirdDisabled: false,
      fourthDisabled: false,
      fiveDisabled: false,
      secondDisabled: false,
      invoiceUrlVis: false,
      onlineInfo: {
        contractNo: "",
        monthVolumeList: [{ date: "", volume: "", key: 0 }],
      },
      goodsInfoList: {
        coalName: "",
        price: "",
        quantity: "",
        totalAmount: "",
        quota: "",
      },
      goodsInfoListrules: {
        coalName: [
          {
            required: true,
            message: "请输入产品名称",
            trigger: "blur",
          },
        ],
        price: [
          {
            required: true,
            message: "请输入产品单价",
            trigger: "blur",
          },
        ],
        quantity: [
          {
            required: true,
            message: "请输入产品数量",
            trigger: "blur",
          },
        ],
        totalAmount: [
          {
            required: true,
            message: "请输入产品总价",
            trigger: "blur",
          },
        ],
        quota: [
          {
            required: true,
            message: "请输入产品指标",
            trigger: "blur",
          },
        ],
      },
      onlineInforules: {
        contractNo: [
          {
            required: true,
            message: "请输入合同编号",
            trigger: "blur",
          },
        ],
        supplier: [
          {
            required: true,
            message: "请输入甲方",
            trigger: "blur",
          },
        ],
        demander: [
          {
            required: true,
            message: "请输入乙方",
            trigger: "blur",
          },
        ],
        supplierContact: [
          {
            required: true,
            message: "请输入联系人",
            trigger: "blur",
          },
        ],
        demanderContact: [
          {
            required: true,
            message: "请输入联系人",
            trigger: "blur",
          },
        ],
        demanderSigningDate: [
          {
            required: true,
            message: "请选择签订日期",
            trigger: "blur",
          },
        ],
        transportType: [
          {
            required: true,
            message: "请选择运输方式",
            trigger: "change",
          },
        ],
        effectiveStartDate: [
          {
            required: true,
            message: "请选择有效开始时间",
            trigger: "blur",
          },
        ],
        effectiveEndDate: [
          {
            required: true,
            message: "请选择有效结束时间",
            trigger: "blur",
          },
        ],
        deliveryType: [
          {
            required: true,
            message: "请选择交货方式",
            trigger: "change",
          },
        ],
        signingPlace: [
          {
            required: true,
            message: "请输入签订地",
            trigger: "blur",
          },
        ],
        payType: [
          {
            required: true,
            message: "请选择付款方式",
            trigger: "change",
          },
        ],
        number: [
          {
            required: true,
            message: "请输入数量",
            trigger: "blur",
          },
        ],
        distributionType: [
          {
            required: true,
            message: "请选择配送方式",
            trigger: "change",
          },
        ],
        totalAmount: [
          {
            required: true,
            message: "请输入总额",
            trigger: "blur",
          },
        ],
        deliveryAddress: [
          {
            required: true,
            message: "请输入交换地",
            trigger: "blur",
          },
        ],
      },
      onlineInForm: {},
      dialogTableVisibles: false,
      signatureName: "",
      signaturePwd: "",
      Softsign: false,
      serverUrl: "",
      dialogVisibless: false,
      numberValidateForm: {
        account: "",
        sign_pwd: "",
      },
      numberValidateForms: {
        name: "",
        card_id: "",
      },
      dialogVisibles: false,
      dialogVisible: false,
      passWordIsShows: false,
      headertype: 0,
      dialogTitle: "",
      citys: citys,
      documentId: "",
      formData: {},
      nameList: [],
      transportations: false,
      ficenum: 0,
      istrafficDocUrls: false,
      trafficDocUrlslist: [],
      receivingVisi: false,
      ransportviews: [],
      transportfileList: [],
      deliverCount: null,
      receivingVisiblebel: false,
      signId: null,
      isshipments: false,
      receivingVisibles: false,
      inspectfileLists: [],
      inspectfileList: [],
      isoffline: false,
      isaccount: false,
      sunbmitpreview: [],
      contimgs: [],
      imglist: [],
      fileList: [],
      sunbmitpreviews: [],
      data: {},
      fileLists: [],
      fileBaseUrl: baseUrl + url.getFileUrl,
      dialogopreviews: false,
      thepreview: {
        inspectUrl: "",
        invoiceUrl: "",
        receiptUrl: "",
        signCount: "",
      },
      formatter: {
        total(row) {
          return row.payAmount * row.payCount;
        },
        trafficWay(row) {
          let _data = {
            0: "未知",
            1: "公路",
            2: "铁路",
            3: "公铁联运",
            4: "海运",
          };
          return _data[row.trafficWay];
        },
      },
      radio: "",
      gridDatas: [
        {
          contractNum: "印章名称",
          contractName: 1,
          contracCode: "5632",
          contracType: "类型",
          contracTypename: "印章类型名称",
          createTime: "2021-11-23 20:00",
        },
      ],
      receivingVisibless: false,
      dialogopreview: false,
      // 签收货物
      receivingVisible: false,
      receivingForm: {
        signCount: "", //签收数量
        inspectUrl: "", //煤炭检验单访问地址
        invoiceUrl: "", //结算单据
        settleWay: 0,
        //receiptUrls: [], //电厂收货单访问地址

        // signCount: "", //签收数量
        // inspectUrl: "", //煤炭检验单访问地址
        // invoiceUrl: "", //结算单据
        // //receiptUrls: [], //电厂收货单访问地址
        // settleWay: 1,
        // qualityDocumentList: [],
        // settleDocumentList: [],
        // settleDetailList: [
        //   {
        //     categoryId: "",
        //     number: "",
        //     goodsPrice: "",
        //     key: 0,
        //   },
        // ],
      },
      numberValidateFormrules: {
        account: [
          {
            required: true,
            message: "请输入账号",
            trigger: "blur",
          },
        ],
        sign_pwd: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
        ],
      },
      receivingFormRule: {
        signCount: [
          {
            required: true,
            message: "请输入结算数量",
            trigger: "blur",
          },
        ],
        invoiceUrl: [
          {
            required: true,
            message: "请上传结算单据",
            trigger: "blur",
          },
        ],
      },
      //发布方true/参与方false
      isPublisher: true,
      // 确认合同上没上传
      contrac: {},
      //预览
      baseUrl: baseUrl,
      preview: false,
      previewImage: "",
      // 发起运输
      transportation: false,
      transport: {
        trafficCount: "",
        startPlace: "",
        trafficWay: "",
        destPlace: "",
        contacts: "",
        contactInfo: "",
        fileIds: [], //运输单据
      },
      transports: {
        trafficCount: "", //运输数量
        trafficPrice: "", //运输单价
        goodsName: "", //商品名称
        payWay: "", //付款类型
        contacts: "", //联系人
        contactInfo: "", //联系方式
        startPlace: "", //起始地
        destPlace: "", //目的地
      },
      // 发起开票form
      formLabelWidth: "150px",
      dialogFormVisible: false,
      form: {
        invoiceAmount: "",
        invoiceCount: "",
        invoiceUrl: "", //开票文件地址
        settleUrl: "", //结算文件地址
        invoiceUrlList: [],
        invoiceDetailList: [
          {
            categoryId: "",
            number: 0,
            goodsPrice: 0,
            key: 0,
          },
        ],
      },
      formRules: {
        invoiceAmount: [
          {
            required: true,
            message: "请输入开票金额",
            trigger: "blur",
          },
        ],
        invoiceCount: [
          {
            required: true,
            message: "请输入开票数量",
            trigger: "blur",
          },
        ],
        invoiceUrl: [
          {
            required: true,
            message: "请上传发票单据",
            trigger: "blur",
          },
        ], //开票文件地址
        settleUrl: [
          {
            required: true,
            message: "请上传开票信息",
            trigger: "blur",
          },
        ], //结算文件地址
      },
      transportRules: {
        trafficCount: [
          {
            required: true,
            message: "请输入运输数量",
            trigger: "blur",
          },
          {
            pattern: /^[0-9]+(\.[0-9]{1,4})?$/,
            message: "请输入正确运输数量",
            trigger: "blur",
          },
        ],
        trafficPrice: [
          {
            required: true,
            message: "请输入运输单价",
            trigger: "blur",
          },
        ],
        goodsName: [
          {
            required: true,
            message: "请选择商品名称",
            trigger: "change",
          },
        ],
        payWay: [
          {
            required: true,
            message: "请选择付款类型",
            trigger: "change",
          },
        ],
        startPlace: [
          {
            required: true,
            message: "请输入起始地",
            trigger: "blur",
          },
        ],
        destPlace: [
          {
            required: true,
            message: "请输入目的地",
            trigger: "blur",
          },
        ],
        contacts: [
          {
            required: true,
            message: "请输入联系人名称",
            trigger: "blur",
          },
        ],
        contactInfo: [
          {
            required: true,
            message: "请输入联系方式",
            trigger: "blur",
          },
          {
            pattern: /^[1][0-9]{10}$/,
            message: "请输入正确手机号码",
            trigger: "blur",
          },
        ],
      },
      transportRule: {
        trafficCount: [
          {
            required: true,
            message: "请输入运输数量",
            trigger: "blur",
          },
          {
            pattern: /^[0-9]+(\.[0-9]{1,4})?$/,
            message: "请输入正确运输数量",
            trigger: "blur",
          },
        ],
        trafficWay: [
          {
            required: true,
            message: "请选择运输方式",
            trigger: "change",
          },
        ],
        startPlace: [
          {
            required: true,
            message: "请输入起始地",
            trigger: "blur",
          },
        ],
        destPlace: [
          {
            required: true,
            message: "请输入目的地",
            trigger: "blur",
          },
        ],
        contacts: [
          {
            required: true,
            message: "请输入联系人名称",
            trigger: "blur",
          },
        ],
        contactInfo: [
          {
            required: true,
            message: "请输入联系方式",
            trigger: "blur",
          },
          {
            pattern: /^[1][0-9]{10}$/,
            message: "请输入正确手机号码",
            trigger: "blur",
          },
        ],
      },
      // //////////////////
      dialogTableVisible: false,
      //支付列表
      tableDatas: [],
      //线下支付
      dialogofflineForm: false,
      offlineForm: {
        payPrice: "",
        quantity: "", //总数量
        payAmount: "", //支付金额
        payCount: "", //支付数量‘
        bankType: "",
        payBrand: 0,
        priceDetailList: [
          {
            categoryId: "",
            number: "",
            goodsPrice: "",
            key: 0,
          },
        ],
      },
      offlineFormRule: {
        bankType: [
          { required: true, message: "请选择支付方式", trigger: "change" },
        ],
        payCount: [
          {
            required: true,
            message: "请输入支付数量",
            trigger: "blur",
          },
        ],
        payPrice: [
          {
            required: true,
            message: "请输入单价",
            trigger: "blur",
          },
        ],
      },
      // 企业详情信息(银行信息)
      enterprise: {},
      //
      gridData: [],
      activeName: "first",
      //开票列表
      tableData: [],
      // 运输列表
      tralist: [],
      //签收列表
      signlist: [],
      paymentId: 0,
      sellerId: null,
      num: [],
      orderSurplusCount: 0,
      settleUrls: "",
      deliveryMode: null,
      fiel: [],
      productlist: false,
      formInline: {
        userId: userId,
        name: "",
        dictId: 506,
        pageSize: 1000,
        pageNum: 1,
      },
      tableList: [],
      contractId: "",
      contractState: {},
      dictList: [],
      goodsName: "",
      firstDisplay: false,
      threeDisplay: false,
      thirdDisplay: false,
      fourthDisplay: false,
      fiveDisplay: false,
      secondDisplay: false,
      isLogistics: false,
      orderStatus: null,
    };
  },
  watch: {
    "onlineInfo.contractTime": {
      handler() {
        if (this.onlineInfo.contractTime?.length) {
          this.onlineInfo.contractStartDate = this.onlineInfo.contractTime[0];
          this.onlineInfo.contractEndDate = this.onlineInfo.contractTime[1];
        } else {
          this.onlineInfo.contractStartDate = "";
          this.onlineInfo.contractEndDate = "";
        }
      },
      deep: true,
    },
  },
  filters: {
    initContrac(index) {
      console.log(index);
      let arr = [
        "未签章",
        "发布方已签章,等待参与方签章",
        "参与方已签章,等待发布方签章",
        "双方都已签章",
      ];

      return arr[index];
    },
    formatType(row) {
      console.log(row);
      switch (row) {
        case 1:
          return "公路运输";
          break;
        case 2:
          return "铁路运输";
          break;
        case 3:
          return "公铁联运";
          break;
        case 4:
          return "海运";
          break;
        default:
          return " ";
      }
    },
  },
  created() {},
  mounted() {
    this.isLogistics = this.$route.query.isLogistics;
    this.getorder();
    this.isPublisher = this.$route.query.isPublisher;
    this.deliveryMode = this.$route.query.deliveryMode;
    this.getCompany();
    this.getGoodsName();
    this.getContractState();
    this.orderList(this.$route.query.orderId);
  },
  methods: {
    addFreightVolume() {
      this.onlineInfo.monthVolumeList.push({
        key: this.onlineInfo.monthVolumeList.length,
        date: "",
        number: "",
      });
    },
    deleteFreightVolume(key) {
      let _index = this.onlineInfo.monthVolumeList.findIndex(
        (item) => item.key == key
      );
      if (_index != -1) {
        this.onlineInfo.monthVolumeList.splice(_index, 1);
      }
      this.onlineInfo.monthVolumeList.forEach((item, index) => {
        item.key = index;
      });
    },
    orderList(id) {
      listTbOrderDeatil(id).then((res) => {
        if (res.code == 0) {
          this.orderStatus = res.data.orderStatus;
        }
      });
    },
    btnClick() {
      let config = {
        orderId: this.$route.query.orderId,
        searchType: 1,
      };
      getAccountBalanceByParam(config).then((res) => {
        if (res.code == 0) {
          this.balances = res.data;
          this.dialogofflineForm = true;
        } else {
          this.$message({
            type: "error",
            message: res.message,
          });
        }
      });
    },
    setactiveName() {
      let enterpriseId = Vue.ls.get(types.enterpriseId);
      let userId = Vue.ls.get(types.userId);
      if (enterpriseId != userId) {
        let obj = [];
        if (
          !this.beforhandle("personalCenter:orderInfo:detail:contract", "1")
        ) {
          this.firstDisabled = true;
        } else {
          // this.activeName = "first";
          obj.push({
            name: "hetong",
            value: "first",
          });
        }
        if (
          !this.beforhandle("personalCenter:orderInfo:detail:signature", "1")
        ) {
          this.threeDisabled = true;
        } else {
          if (this.contrac.contractType == 2) {
            obj.push({
              name: "qianzhang",
              value: "three",
            });
            this.getorder();
          }
        }
        if (!this.beforhandle("personalCenter:orderInfo:detail:pay", "1")) {
          this.thirdDisabled = true;
        } else {
          obj.push({
            name: "zhifu",
            value: "third",
          });

          if (
            (this.contrac.signatureStatus == null
              ? this.contrac.contractStatus != 3
              : this.contrac.signatureStatus != 3) == false
          ) {
            this.paymentpage();
          }
        }
        if (
          !this.beforhandle("personalCenter:orderInfo:detail:transport", "1")
        ) {
          this.fourthDisabled = true;
        } else {
          obj.push({
            name: "yunshu",
            value: "fourth",
          });

          if (
            (this.contrac.signatureStatus == null
              ? this.contrac.contractStatus != 3
              : this.contrac.signatureStatus != 3) == false
          ) {
            this.tralists();
          }
        }
        if (
          !this.beforhandle("personalCenter:orderInfo:detail:settlement", "1")
        ) {
          this.fiveDisabled = true;
        } else {
          obj.push({
            name: "jiesuan",
            value: "five",
          });

          if (
            (this.contrac.signatureStatus == null
              ? this.contrac.contractStatus != 3
              : this.contrac.signatureStatus != 3) == false
          ) {
            this.signgetlist();
          }
        }
        if (!this.beforhandle("personalCenter:orderInfo:detail:billing", "1")) {
          this.secondDisabled = true;
        } else {
          obj.push({
            name: "kaipiao",
            value: "second",
          });

          if (
            (this.contrac.signatureStatus == null
              ? this.contrac.contractStatus != 3
              : this.contrac.signatureStatus != 3) == false
          ) {
            this.invoicepage();
          }
        }
        console.log(this.firstDisabled, "111111111111111111111111111111111111");

        this.activeName = obj[0].value;
      }
    },
    getparentLevel() {
      getCompanyAuthDetail().then((res) => {
        console.log(res);
        this.numberValidateForms.name = res.data.companyName;
        this.numberValidateForms.card_id = res.data.socialCode;
      });
    },
    closeChan() {
      this.receivingForm = {
        signCount: "", //签收数量
        inspectUrl: "", //煤炭检验单访问地址
        invoiceUrl: "", //结算单据
        settleWay: 0,
      };
    },
    closeChang() {
      this.transport = {};
      this.fiel = [];
      this.transport.fileIds = [];
      this.ransportviews = [];
    },
    delImg() {
      this.fiel = [];
      this.transport.fileIds = [];
      this.ransportviews = [];
    },
    getContractState() {
      listTbOrderDeatil(this.$route.query.orderId)
        .then((res) => {
          if (res.code == 0) {
            this.contractState = res.data;
            this.offlineForm.payPrice = res.data.unitPrice;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    closeDialogopreview(param) {
      this.dialogopreview = param;
      this.getorder();
      this.getContractState();
    },
    async getList() {
      const res = await serviceGoodCollection(this.formInline);
      if (res.code == 0) {
        console.log(res);
        this.tableList = res.data.list;
      }
    },
    getGoodCollection() {
      let id = Vue.ls.get(types.enterpriseId);
      let config = {
        dictId: 506,
        name: "",
        pageNum: 1,
        pageSize: 1000,
        userId: id,
      };
      serviceGoodCollection(config).then((res) => {
        console.log(res);
        if (res.code == 0) {
          if (res.data.total > 0) {
            this.tableList = res.data.list;
            this.productlist = true;
            this.transportations = false;
          } else {
            console.log("没有关注的服务商");
            this.$confirm(
              "您还未关注物流服务商品，请先前往服务大厅进行关注！",
              "提示",
              {
                confirmButtonText: "立即前往",
                cancelButtonText: "取消",
                type: "warning",
              }
            )
              .then(() => {
                this.beforeClose();
                this.$router.push({
                  path: "/financial",
                  query: {
                    id: 14,
                    title: "服务大厅",
                  },
                });
              })
              .catch(() => {
                this.transportations = false;
              });
          }
        }
      });
    },
    // 服务商运输
    carRiages() {
      console.log(this.transports.goodsName, this.goodsName);
      this.transports.startPlace = this.transports.startPlace
        .toString()
        .replace(new RegExp(",", "gm"), "-");
      this.transports.destPlace = this.transports.destPlace
        .toString()
        .replace(new RegExp(",", "gm"), "-");
      this.$refs.transports.validate((valid) => {
        if (valid) {
          this.getGoodCollection();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    beforeClose() {
      this.$refs.transports.resetFields();
      this.transportations = false;
    },
    transmitClick(item) {
      this.transports.orderId = this.$route.query.orderId;
      this.transports.goodsName = this.goodsName;
      this.transports.trafficType = 2;
      console.log(this.transport);
      addtrafficpage(this.transports).then((res) => {
        if (res.code == 0) {
          let config = {
            tradeTrafficId: res.data.trafficId,
            serviceGoodId: item.serviceGoodId,
          };
          placeTransportOrderFromTrade(config).then((ret) => {
            if (ret.code == 0) {
              this.$message({
                message: "发起成功",
                type: "success",
              });
              this.productlist = false;
              this.beforeClose();
              this.tralists();
              this.transports = {
                trafficCount: "", //运输数量
                trafficPrice: "", //运输单价
                goodsName: "", //商品名称
                payWay: "", //付款类型
                contacts: "", //联系人
                contactInfo: "", //联系方式
                startPlace: "", //起始地
                destPlace: "", //目的地
              };
            }
          });
        }
        console.log(res);
      });
    },
    getGoodsName() {
      reqDictType({
        dictType: "coal_type",
      })
        .then((ret) => {
          if (ret.code == 0) {
            this.dictList = JSON.parse(JSON.stringify(ret.data.children));
            let data = ret.data.children;
            for (let i = 0; i < data.length; i++) {
              let item = data[i];
              for (let j = 0; j < item.children.length; j++) {
                let sub = item.children[j];
                delete sub.children;
              }
            }
            this.nameList = data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    goodsChange(e) {
      console.log(e, "开始");
      this.transport.goodsName = e[1];
      outer: for (let i = 0; i < this.dictList.length; i++) {
        let item = this.dictList[i];
        for (let j = 0; j < item.children.length; j++) {
          let sub = item.children[j];
          if (item.id == e[0] && sub.id == e[1]) {
            sub.children.forEach((item) => {
              // console.log(item)
              this.$set(item, "quotaValue", "");
              console.log(item);
            });
            this.goodsName = sub.dictName;
            this.formData.quotaList = sub.children.map((item) => {
              let arr = item.dictValue.split(";");
              return {
                ...item,
                dictName: item.dictName + "(" + arr[0] + ")" + arr[2],
                dictValue: arr[1],
              };
            });
            console.log(this.formData.quotaList);
            break outer;
          }
        }
      }
    },
    trafficDocUrlsClick(item) {
      this.istrafficDocUrls = true;
      this.trafficDocUrlslist = item;
    },
    //发起开票选择开票信息
    addsettleUrlres(file) {
      let isPass = this.checkPic(file);
      if (isPass) {
        let otherFiles = file.file;
        var formData = new FormData();
        // 文件对象
        formData.append("file", otherFiles);
        ossUploadFile(formData).then((res) => {
          if (res.code == 0) {
            this.$message({
              message: "上传成功",
              type: "success",
            });
            this.settleUrls = res.data;
            // this.$refs.invoiceForm.validateField(["settleUrl"]);
          }
        });
      }
    },
    // carRiageres
    carRiageres() {
      let config = {
        signId: this.signId,
        settleDocument: this.settleUrls,
      };
      if (this.settleUrls == "") {
        this.$message.error("请上传结算单据");
      } else {
        confirmSettle(config).then((res) => {
          if (res.code == 0) {
            this.$message({
              message: "已确认收货",
              type: "success",
            });
            this.receivingVisiblebel = false;
            this.signgetlist();
          } else {
            this.$message.error(res.data);
          }
        });
      }
    },
    // 确认发货
    ipmentsClick() {
      let config = {
        orderId: this.$route.query.orderId,
        deliverCount: this.deliverCount,
      };
      confirmDeliver(config).then((res) => {
        if (res.code == 0) {
          this.isshipments = false;
          this.signgetlist();
        }
      });
    },
    inspectpreviews() {
      this.receivingVisibless = true;
    },
    handleChangess(file, fileList) {
      this.inspectfileLists = fileList.slice(-1000);
    },
    noteconfirm(row) {
      console.log(row.invoiceId);
      let config = {
        invoiceId: row.invoiceId,
      };
      confirmInvoice(config).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.$message({
            message: "确认成功",
            type: "success",
          });
          this.invoicepage();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    examinecontract(item) {
      //ie兼容处理
      getContractOSSFile(item, {
        responseType: "blob",
      }).then((res) => {
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(res, item);
        } else {
          // for Non-IE
          let objectUrl = URL.createObjectURL(res);
          let link = document.createElement("a");
          link.href = objectUrl;
          link.setAttribute("download", item);
          document.body.appendChild(link);
          link.click();
          window.URL.revokeObjectURL(link.href);
          document.body.removeChild(link);
        }
      });
    },
    handleExceedel(files, fileList) {
      this.$message.warning(
        `当前限制选择 1000 个文件，本次选择了 ${
          files.length
        } 个文件，共选择了 ${files.length + fileList.length} 个文件`
      );
    },
    handleExceeds(files, fileList) {
      this.$message.warning(
        `当前限制选择 1000 个文件，本次选择了 ${
          files.length
        } 个文件，共选择了 ${files.length + fileList.length} 个文件`
      );
    },
    handleChangerel(file, fileList) {
      this.inspectfileList = fileList.slice(-1000);
    },
    handleExceedss(files, fileList) {
      this.$message.warning(
        `当前限制选择 1000 个文件，本次选择了 ${
          files.length
        } 个文件，共选择了 ${files.length + fileList.length} 个文件`
      );
    },
    handleExceed(files, fileList) {
      console.log(files);
      this.$message.warning(
        `当前限制选择 1000 个文件，本次选择了 ${
          files.length
        } 个文件，共选择了 ${files.length + fileList.length} 个文件`
      );
    },
    accountClickss(row) {
      console.log(row);
      this.contimgs = row.contractUrls;
      this.isaccount = true;
    },
    // 上传合同
    beforeAvatarUpload(file) {
      let isJPG;
      if (file.type === "application/pdf") {
        isJPG = true;
      } else {
        isJPG = false;
        this.$message.error("请上传pdf文件!");
      }
      if (!isJPG) {
        return false;
      }
    },
    handleChange(file, fileList) {
      if (file.raw.type && file.raw.type == "application/pdf") {
        this.fileList = fileList.slice(-1000);
      }
    },
    handleChanges(file, fileList) {
      this.inspectfileList = fileList.slice(-1000);
    },
    beforeRemove(file) {
      if (file.raw && file.raw.type == "application/pdf") {
        return this.$confirm(`确定移除 ${file.name}？`);
      }
    },
    //保存合同地址
    addcontract() {
      if (this.imglist.length == 0) {
        this.$message.error("请上传合同!");
      } else {
        console.log(this.imglist);
        let name = this.imglist[0].storageName;
        let ids = [];
        for (let i = 0; i < this.imglist.length; i++) {
          ids.push(this.imglist[i].fileId);
        }
        let config = {
          contractUrls: ids,
          orderId: this.$route.query.orderId,
          contractName: name,
          contractType: 1,
          ifAnnex: 0,
        };
        saveContractUrl(config).then((res) => {
          console.log(res);
          if (res.code == 0) {
            this.$message({
              message: "上传成功",
              type: "success",
            });
            this.$refs.upload.clearFiles();
            this.getorder();
          } else {
            this.$message(res.message);
          }
        });
      }
      this.imglist = [];
    },
    handleImageBeforeUpload(file) {
      let otherFiles = file.file;
      console.log(this.fileList);
      var formData = new FormData();
      formData.append("file", otherFiles);
      ossUploadFiletwo(formData).then((res) => {
        if (res.code == 0) {
          this.imglist.push(res.data);
          this.$message({
            message: res.message,
            type: "success",
          });
        }
      });
    },
    handleSelectionChange(selection) {
      console.log(selection);
      this.radio = selection[0];
      if (selection.length > 1) {
        this.$refs.tb.clearSelection();
        this.$refs.tb.toggleRowSelection(selection.pop());
      }
    },
    inspectpreview() {
      this.receivingVisibles = true;
    },
    copyFn(value) {
      let oInput = document.createElement("textarea");
      oInput.value = value;
      document.body.appendChild(oInput);
      oInput.select();
      document.execCommand("Copy");
      oInput.style.display = "none";
      document.body.removeChild(oInput);
      this.$message.success("复制成功");
    },
    formatFloat(name) {
      this.offlineForm[name] =
        this.offlineForm[name].match(/^\d*(\.?\d{0,23})/g)[0] || "";
      if (name == "payCount") {
        if (this.offlineForm[name].indexOf(".") > 0) {
          if (this.offlineForm[name] * 10 <= 1) {
            console.log(parseInt(this.offlineForm[name] * 10) / 10);
            if (this.offlineForm[name] == "0.0") {
              this.offlineForm[name] = 0;
            }
            if (this.offlineForm[name] == "0.10") {
              this.offlineForm[name] = "0.1";
            }
          } else {
            this.offlineForm[name] = this.offlineForm[name].substring(
              0,
              this.offlineForm[name].indexOf(".") + 5
            );
          }
        } else {
          this.offlineForm[name] = this.offlineForm[name].substring(0, 7);
        }
        let a =
          (this.offlineForm.payPrice * (this.offlineForm.payCount * 10)) / 10;
        let b = a.toString();
        if (b.indexOf(".") != -1) {
          console.log(b.substring(0, b.indexOf(".") + 3));
          this.ficenum = b.substring(0, b.indexOf(".") + 3);
        } else {
          this.ficenum = b;
        }
      }
      if (name == "payPrice") {
        if (this.offlineForm[name].indexOf(".") > 0) {
          this.offlineForm[name] = this.offlineForm[name].substring(
            0,
            this.offlineForm[name].indexOf(".") + 5
          );
        }
        let a =
          (this.offlineForm.payPrice * (this.offlineForm.payCount * 10)) / 10;
        let b = a.toString();
        if (b.indexOf(".") != -1) {
          this.ficenum = b.substring(0, b.indexOf(".") + 3);
        } else {
          this.ficenum = b;
        }
      }
    },
    formInvoiceAmount(e) {
      this.form.invoiceAmount = e.target.value;
    },
    formInvoiceCount(e) {
      this.form.invoiceCount = e.target.value;
    },
    offlineFormPayPrice(e) {
      this.offlineForm.payPrice = e.target.value;
    },
    offlineFormPayCount(e) {
      this.offlineForm.payCount = e.target.value;
    },
    transportContactInfo(e) {
      this.transport.contactInfo = e.target.value;
    },
    transportContactInfos(e) {
      this.transports.contactInfo = e.target.value;
    },
    receivingFormSignCountss(e) {
      this.transports.trafficCount = e.match(/^[0-9]+(\.[0-9]{1,3})?$/g) || "";
      if (e.indexOf(".") > 0) {
        this.transports.trafficCount = e.substring(0, e.indexOf(".") + 5);
      } else {
        this.transports.trafficCount = e;
      }
      console.log(this.transports.trafficCount, e);
    },
    receivingFormSignCount(name) {
      if (name == "signCount") {
        this.receivingForm[name] =
          this.receivingForm[name].match(/^\d*(\.?\d{0,23})/g)[0] || "";
        if (this.receivingForm[name].indexOf(".") > 0) {
          this.receivingForm[name] = this.receivingForm[name].substring(0, 10);
        } else {
          this.receivingForm[name] = this.receivingForm[name].substring(0, 5);
        }
      }
      if (name == "invoiceCount" || name == "invoiceAmount") {
        this.form[name] = this.form[name].match(/^\d*(\.?\d{0,23})/g)[0] || "";
        if (this.form[name].indexOf(".") > 0) {
          this.form[name] = this.form[name].substring(
            0,
            this.form[name].indexOf(".") + 5
          );
        }
      } else if (name == "trafficCount") {
        console.log(this.transport[name]);
        this.transport[name] =
          this.transport[name].match(/^[0-9]+(\.[0-9]{1,3})?$/g)[0] || "";
        if (this.transport[name].indexOf(".") > 0) {
          this.transport[name] = this.transport[name].substring(
            0,
            this.transport[name].indexOf(".") + 5
          );
        }
      } else {
        this.receivingForm[name] =
          this.receivingForm[name].match(/^\d*(\.?\d{0,23})/g)[0] || "";
        if (this.receivingForm[name].indexOf(".") > 0) {
          this.receivingForm[name] = this.receivingForm[name].substring(
            0,
            this.receivingForm[name].indexOf(".") + 5
          );
        }
      }
    },
    transportTrafficCount(e) {
      this.transports.trafficCount = e.target.value;
    },
    transportStartPlace(e) {
      this.transport.startPlace = e.target.value;
    },
    transportDestPlace(e) {
      this.transport.destPlace = e.target.value;
    },
    transportContacts(e) {
      this.transport.contacts = e.target.value;
    },
    transportContactss(e) {
      this.transports.contacts = e.target.value;
    },
    addVoucher(row) {
      this.paymentId = row.paymentId;
    },
    parentEvent(data) {
      console.log(data, "9999999999999");
      if (this.$route.query.tradeType == 1) {
        if (data.tradeGoodsListing.listingPrice == 0) {
          this.offlineForm.payPrice = "";
          this.isoffline = false;
        } else {
          this.isoffline = true;
          this.offlineForm.payPrice = data.tradeGoodsListing.listingPrice;
        }
      } else {
        console.log();
        if (data.categoryList[0].dealPrice == 0) {
          this.offlineForm.payPrice = "";
          this.isoffline = false;
        } else {
          this.isoffline = true;
          this.offlineForm.payPrice = data.categoryList[0].dealPrice;
        }
      }
      this.data = data;
      this.sellerId = data.sellerId;
      this.offlineForm.quantity = data.tradeGoods.stockCount;
      if (data.overOrderCount == 0 || data.overOrderCount < 0) {
        this.offlineForm.payCount = 0;
        this.orderSurplusCount = 0;
      } else {
        this.offlineForm.payCount = data.overOrderCount;
        this.orderSurplusCount = data.overOrderCount;
      }
    },
    //获取合同
    getorder() {
      let orderId = this.$route.query.orderId;
      detailByOrder(orderId).then((res) => {
        if (res.code == 0) {
          this.contrac = res.data;
          this.contractId = res.data.contractId;
          console.log(res.data.contractId);
          if (res.data.contractUrls) {
            if (res.data.contractUrls.length) {
              this.documentId = res.data.contractUrls[0].documentId;
            }
          }
          this.setactiveName();
        }
      });
    },
    traderFinish(row) {
      let config = {
        confirm: row,
        orderId: this.$route.query.orderId,
      };
      finish(config).then((res) => {
        return res;
      });
    },
    // 选择模板单选
    getCurrentRow(row) {
      console.log(row);
      this.radio = row;
    },
    getOnlineContractInfo(id) {
      getOnlineContractTransportInfo(id).then((res) => {
        console.log(res);
        this.dialogTableVisibles = true;
        if (res.code == 0) {
          this.onlineInfo = res.data;
          this.onlineInfo.contractTime = [
            res.data.contractStartDate,
            res.data.contractEndDate,
          ];
          this.onlineInfo.monthVolumeList = this.onlineInfo.monthVolumeList
            ?.length
            ? this.onlineInfo.monthVolumeList.map((item, index) => {
                return {
                  ...item,
                  key: index,
                };
              })
            : [{ date: "", volume: "", key: 0 }];
        }
      });
    },
    onSubmit() {
      //  goodsInfoList
      this.$refs["onlineInfo"].validate((valid) => {
        console.log(this.onlineInfo);
        if (valid) {
          // this.$refs["goodsInfoList"].validate((val) => {
          //   if (val) {
          //     console.log(this.onlineInfo.demanderSigningDate);
          //     const loading = this.$loading({
          //       lock: true,
          //       text: "Loading",
          //       spinner: "el-icon-loading",
          //       background: "rgba(0, 0, 0, 0.7)",
          //     });
          //     let operateType;
          //     if (this.$route.query.isPublisher) {
          //       operateType = 1;
          //     } else {
          //       operateType = 2;
          //     }
          //     let config = {
          //       goodsInfoList: [this.goodsInfoList],
          //       onlineInfo: this.onlineInfo,
          //       operateType: operateType,
          //     };
          let config = {
            ...this.onlineInfo,
          };
          delete config.supplierSignDate;
          delete config.demanderSignDate;
          updateOnlineContractTransportInfo(config).then((res) => {
            console.log(res);
            if (res.code == 0) {
              this.dialogTableVisibles = false;
              let config = {
                orderId: this.$route.query.orderId,
                contractId: this.contractId,
              };
              confirm(config).then((res) => {
                if (res.code == 0) {
                  // loading.close();
                  this.$message({
                    type: "success",
                    message: "已确认合同无误!",
                  });
                  this.getorder();
                  try {
                    location.reload();
                  } catch (error) {}
                }
              });
            } else {
              this.$message.error(res.message);
              loading.close();
            }
          });
          //   }
          // });
        }
      });
    },
    //选择合同模板
    CurrentRowClick() {
      if (!this.radio) {
        this.$message({
          message: "请选择合同  ",
          type: "warning",
        });
        return false;
      } else if (!this.radio.contractUrl) {
        this.$message({
          message: "合同地址无效",
          type: "warning",
        });
        return false;
      }

      let contractUrls = [];

      contractUrls.push(this.radio.contractUrl);
      let config = {
        contractUrls: contractUrls,
        orderId: this.$route.query.orderId,
        contractName: this.radio.contractName,
        contractType: 2,
        ifAnnex: 0,
      };
      saveContractUrl(config).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.$message({
            message: "上传成功",
            type: "success",
          });
          this.dialogTableVisible = false;
          this.getorder();
        } else {
          this.$message(res.message);
        }
      });
    },
    sendMsg(param, val, type) {
      let data = JSON.stringify(param);
      document.getElementById("topFrame").contentWindow.postMessage(data, "*");
      if (val) {
        if (type == "numberValidateForm") {
          this.Softsign = 1;
          this.signatureName = this.numberValidateForm.account;
          this.signaturePwd = this.numberValidateForm.sign_pwd;
          this.numberValidateForm.account = "";
          this.numberValidateForm.sign_pwd = "";
        } else if (type == "numberValidateForms") {
          this.Softsign = 2;
          this.signatureName = this.numberValidateForms.name;
          this.signaturePwd = this.numberValidateForms.card_id;
          this.numberValidateForms.name = "";
          this.numberValidateForms.card_id = "";
        }
        this.dialogopreview = true;
        this.dialogVisibles = false;
        this.dialogVisibless = false;
      }
    },
    numberValidateFormclose() {
      this.$refs.numberValidateForm.resetFields();
    },
    dialogVisibleClonse() {
      this.$refs.numberValidateForm.resetFields();
    },
    // 软签提交
    submitForm(val) {
      let _this = this;
      if (val == "numberValidateForm") {
        this.Softsign = 1;
        this.signatureName = this.numberValidateForm.account;
        this.signaturePwd = this.numberValidateForm.sign_pwd;
        this.numberValidateForm.account = "";
        this.numberValidateForm.sign_pwd = "";
      } else if (val == "numberValidateForms") {
        this.Softsign = 2;
        this.signatureName = this.numberValidateForms.name;
        this.signaturePwd = this.numberValidateForms.card_id;
        this.numberValidateForms.name = "";
        this.numberValidateForms.card_id = "";
      }
      this.dialogopreview = true;
      this.dialogVisibles = false;
      this.dialogVisibless = false;
    },
    // 设置证书形式
    setCertSource(val) {
      console.log("3333333333");
      let _this = this;
      let param = {
        type: "setCertSource",
        cert_source: 4,
      };
      let params = {
        type: "setSealPoisiton",
        allow_drag: true,
        position_type: 3,
        coordinate_obj: {},
        seam_obj: {
          // 骑缝可拖动
          seam_type: 1,
          // y_position: 100,
          count: 4, // 印章切割数
          seal_way: 0, // 盖章页范围 0、所有页  1、奇数页  2、偶数页
        },
      };
      _this.sendMsg(param);
      _this.sendMsg(params, "signature", val);
    },
    //软签
    mildSignature() {
      this.getparentLevel();
      this.dialogVisibles = true;
      this.dialogVisible = false;
    },
    //在线签章  点击有签章
    Toobtainan() {
      this.$refs.signDownloadDrive.changeState();
      if (!this.documentId) {
        this.$message({
          type: "info",
          message: "合同地址无效",
        });
        return false;
      }
      this.dialogopreview = true;
      this.dialogVisible = false;
    },
    confirmClicks() {
      this.dialogVisible = true;
    },
    //点击无签章
    cancelClicks() {
      this.$confirm("您还没有添加签章", "提示", {
        confirmButtonText: "去添加",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          getLoginUrl().then((res) => {
            if (res.code == 0) {
              window.open(res.data, "_blank");
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    inspectprevie() {
      this.receivingVisi = true;
    },
    invoiceUrlVisFlse() {
      // this.form.invoiceUrlList=[]
      this.invoiceUrlVis = false;
    },
    // 上传运输单据
    addinspectUrlUrls(file) {
      let isPass = this.checkPic(file);
      if (isPass) {
        let otherFiles = file.file;
        var formData = new FormData();
        // 文件对象
        formData.append("file", otherFiles);
        ossUploadFiletw(formData).then((res) => {
          if (res.code == 0) {
            console.log(res);
            this.$message({
              message: "上传成功",
              type: "success",
            });
            this.ransportviews.push(res.data.storageName);
            this.fiel.push(res.data.fileId);
            this.transport.fileIds = this.fiel;
          }
        });
      }
    },
    // 满意点确认
    confirmClick() {
      let config = {
        orderId: this.$route.query.orderId,
      };
      finish(config).then((res) => {
        switch (res.code) {
          case -1:
            this.$confirm(res.message, "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                this.traderFinish(true);
                this.$message({
                  message: "合同整合完成",
                  type: "success",
                });
                this.$router.go(-1);
              })
              .catch(() => {
                this.$message({
                  type: "info",
                  message: "已取消",
                });
              });
            break;
          case 0:
            this.traderFinish(true);
            this.$message({
              message: "合同整合完成",
              type: "success",
            });
            this.$router.go(-1);
            break;
          case 1:
            this.$message({
              message: res.message,
              type: "error",
            });
            break;
        }
      });
    },
    receivingClickS(item) {
      let config = {
        signId: item.signId,
      };
      confirmSettle(config).then((res) => {
        if (res.code == 0) {
          this.$message({
            message: "已确认结算",
            type: "success",
          });
          this.signgetlist();
        } else {
          this.$message.error(res.data);
        }
      });
    },
    // 签收货物
    receivingClick() {
      (this.receivingForm.orderId = this.$route.query.orderId),
        console.log(this.receivingForm);
      this.$refs.receivingForm.validate((valid) => {
        if (valid) {
          confirmReceive(this.receivingForm).then((res) => {
            if (res.code == 0) {
              this.$message({
                type: "success",
                message: "发起结算成功!",
              });
              this.receivingForm = {
                signCount: "", //签收数量
                inspectUrl: "", //煤炭检验单访问地址
                invoiceUrls: [], //开票票据文件访问地址
                receiptUrls: [], //电厂收货单访问地址
                invoiceUrl: "", //结算单据
                settleWay: 0,
              };
              this.signgetlist();
              this.receivingVisible = false;
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 切换
    handleClick(tab) {
      let _this = this;
      if (tab.label == "支付管理") {
        if (!this.beforhandle("personalCenter:orderInfo:detail:pay"))
          return (this.activeName = "");
        this.paymentpage();
      } else if (tab.label == "运输管理") {
        if (!this.beforhandle("personalCenter:orderInfo:detail:transport"))
          return (this.activeName = "");
        this.tralists();
      } else if (tab.label == "结算管理") {
        if (!this.beforhandle("personalCenter:orderInfo:detail:settlement"))
          return (this.activeName = "");
        this.signgetlist();
      } else if (tab.label == "开票管理") {
        if (!this.beforhandle("personalCenter:orderInfo:detail:billing"))
          return (this.activeName = "");
        _this.invoicepage();
      } else if (tab.label == "在线签章") {
        if (!this.beforhandle("personalCenter:orderInfo:detail:signature"))
          return (this.activeName = "");
        this.getorder();
        this.getContractState();
      } else if (tab.label == "合同管理") {
        if (!this.beforhandle("personalCenter:orderInfo:detail:contract"))
          return (this.activeName = "");
      }
    },
    //线下支付
    flineFormClicks(type) {
      this.offlineForm.orderId = this.$route.query.orderId;
      this.offlineForm.payType = type;
      let priceDetailList = [];
      this.offlineForm.payBrand = 0;
      priceDetailList = [];
      this.offlineForm.payAmount =
        (this.offlineForm.payPrice * (this.offlineForm.payCount * 10)) / 10;
      this.offlineForm.payCount = Number(this.offlineForm.payCount);
      if (this.offlineForm.payCount == 0 || this.offlineForm.payPrice == 0) {
        this.$message({
          type: "info",
          message: "支付数量/单价填写不得为0",
        });
        return false;
      }
      let { orderId, payAmount, payBrand, payCount, payType } =
        this.offlineForm;
      let config = {
        orderId,
        payAmount,
        payBrand,
        payCount,
        payType,
        priceDetailList,
      };

      // this.offlineForm.payAmount =
      //   (this.offlineForm.payPrice * (this.offlineForm.payCount * 10)) / 10;
      // this.offlineForm.payCount = Number(this.offlineForm.payCount);
      // // this.offlineForm.bankType=0
      // let config = this.offlineForm;
      // if (this.offlineForm.payCount == 0 || this.offlineForm.payPrice == 0) {
      //   this.$message({
      //     type: "info",
      //     message: "支付数量/单价填写不得为0",
      //   });
      //   return false;
      // }
      // return
      this.$refs.offlineForm.validate((valid) => {
        if (valid) {
          if (type == 1) {
            addpayment(config).then((res) => {
              console.log(res);
              if (res.code == 0) {
                this.$message({
                  type: "success",
                  message: "支付成功!",
                });
                this.paymentpage();
                this.dialogofflineForm = false;
                try {
                  this.$refs["offlineForm"].resetFields();
                } catch {}
                this.offlineForm = {
                  payPrice: "",
                  quantity: "", //总数量
                  payAmount: "", //支付金额
                  payCount: "", //支付数量‘
                  bankType: "",
                  payBrand: "",
                  priceDetailList: [
                    {
                      categoryId: "",
                      number: "",
                      goodsPrice: "",
                      key: 0,
                    },
                  ],
                };
                // location.reload();
                // if (this.$route.query.orderId) {
                //   this.$refs.parentref.orderList(this.$route.query.orderId);
                // } else {
                //   this.$refs.parentref.getlisst(this.$route.query.goodsId);
                // }
              }
            });
          } else {
            let checkUkeyWhite = Vue.ls.get(types.checkUkeyWhite);
            if (checkUkeyWhite == 0) {
              ESASignClient.ESACheckIsKey(this.JsESACheckIsKeyk, null, false);
            } else {
              this.passWordIsShows = true;
            }
          }
        } else {
          return false;
        }
      });
    },
    JsESACheckIsKeyk(status, msg, retpar, params) {
      let _this = this;
      console.log(status);
      if (status == 0) {
        this.passWordIsShows = true;
      } else {
        alert(msg);
        return false;
      }
    },
    closeDialog(tag) {
      this[tag] = false;
      this.passWordIsShows = false;
    },
    submit(val) {
      let numpice =
        (this.offlineForm.payPrice * (this.offlineForm.payCount * 10)) / 10;
      let config = {
        bankType: this.offlineForm.bankType,
        payPrice: this.offlineForm.payPrice,
        orderId: this.$route.query.orderId,
        payPassword: md5(val),
        payCount: this.offlineForm.payCount,
        payAmount: numpice,
        payBrand: this.offlineForm.payBrand,
        priceDetailList: [],
      };
      console.log(config);
      payTradeOrder(config).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.$message({
            type: "success",
            message: "支付成功",
          });
          this.passWordIsShow = false;
          this.paymentpage();
          this.dialogofflineForm = false;
          try {
            this.$refs["offlineForm"].resetFields();
          } catch {}
          this.offlineForm = {
            payPrice: "",
            quantity: "", //总数量
            payAmount: "", //支付金额
            payCount: "", //支付数量‘
            bankType: "",
            payBrand: "",
            priceDetailList: [
              {
                categoryId: "",
                number: "",
                goodsPrice: "",
                key: 0,
              },
            ],
          };
          // location.reload();
          // if (this.$route.query.orderId) {
          //   this.$refs.parentref.orderList(this.$route.query.orderId);
          // } else {
          //   this.$refs.parentref.getlisst(this.$route.query.goodsId);
          // }
        } else {
          this.$message({
            type: "error",
            message: res.message,
          });
        }
      });
    },
    CloseChange() {
      if (this.$route.query.orderId) {
        this.$refs.parentref.orderList(this.$route.query.orderId);
      } else {
        this.$refs.parentref.getlisst(this.$route.query.goodsId);
      }
    },
    flineFormclick(title) {
      this.ficenum = 0;

      if (title == 1) {
        this.btnClick();
        this.dialogTitle = "线上支付";
        console.log("线上支付");
        this.headertype = 2;
        let a =
          (this.offlineForm.payPrice * (this.offlineForm.payCount * 10)) / 10;
        a = Math.round(a * 100000) / 100000;
        this.ficenum = a;
      } else {
        this.dialogofflineForm = true;
        this.headertype = 1;
        this.dialogTitle = "线下支付";
        this.getCompany();
        let a =
          (this.offlineForm.payPrice * (this.offlineForm.payCount * 10)) / 10;
        a = Math.round(a * 100000) / 100000;
        this.ficenum = a;
      }
    },
    getCompany() {
      let data = {
        enterpriseId: this.sellerId,
      };
      reqCompanyAuthDetail(data).then((res) => {
        this.enterprise = res.data;
      });
    },
    //确认金额已到账
    addconfirm(row) {
      this.$confirm("确认金额到账后无法修改，请谨慎操作!", "提示", {
        confirmButtonText: "已到账",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let config = {
            paymentId: row.paymentId,
          };
          addconfirm(config).then((res) => {
            if (res.code == 0) {
              this.$message({
                type: "success",
                message: "确认成功!",
              });
              this.paymentpage();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消确认",
          });
        });
    },
    //确认合同无误
    countersign() {
      // confirm
      this.$confirm(
        "请认真核对交易信息,确认后无法修改,是否前去确认合同信息?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          if (this.contrac.contractType == 1) {
            let config = {
              orderId: this.$route.query.orderId,
              contractId: this.contractId,
            };
            confirm(config).then((res) => {
              if (res.code == 0) {
                this.$message({
                  type: "success",
                  message: "已确认合同无误!",
                });
                this.getorder();
              }
            });
          } else {
            let id = this.$route.query.orderId;
            this.getOnlineContractInfo(id);
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    //发起开票
    addinvoice() {
      this.$refs.invoiceForm.validate((valid) => {
        if (valid) {
          this.reqAddinvoice();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    reqAddinvoice() {
      this.form.orderId = this.$route.query.orderId;
      this.form.invoiceCount = Number(this.form.invoiceCount);
      this.form.invoiceAmount = Number(this.form.invoiceAmount);
      console.log(this.form);
      // return;
      addinvoice(this.form).then((res) => {
        if (res.code == 0) {
          this.dialogFormVisible = false;
          this.form = {
            invoiceAmount: "",
            invoiceCount: "",
            invoiceUrl: "", //开票文件地址
            settleUrl: "", //结算文件地址
            invoiceUrlList: [],
            invoiceDetailList: [
              {
                categoryId: "",
                number: 0,
                goodsPrice: 0,
                key: 0,
              },
            ],
          };
          this.invoicepage();
        }
      });
    },
    // 签约记录
    signgetlist() {
      let config = {
        orderId: this.$route.query.orderId,
        page: 1,
        size: 1000000,
      };
      signpage(config).then((res) => {
        let list = res.data.records;
        list.forEach((item) => {
          item.createTime = item.createTime.replace("T", " ");
        });
        this.signlist = list;
      });
    },
    //运输记录
    tralists() {
      let config = {
        orderId: this.$route.query.orderId,
        page: 1,
        size: 10000,
      };
      trafficpage(config).then((res) => {
        let list = res.data.records;
        list.forEach((item) => {
          item.createTime = item.createTime.replace("T", " ");
        });
        console.log(list);
        this.tralist = list;
      });
    },
    selectChange(name) {
      this.$refs.transport.clearValidate(name);
    },
    selectChanges(name) {
      this.$refs.transports.clearValidate(name);
    },
    carform() {
      this.transportation = false;
      this.$refs.transport.clearValidate();
    },
    //发起运输
    carRiage() {
      this.$refs.transport.validate((valid) => {
        if (valid) {
          this.transports.trafficType = 1;
          this.transport.startPlace = this.transport.startPlace
            .toString()
            .replace(new RegExp(",", "gm"), "-");
          this.transport.destPlace = this.transport.destPlace
            .toString()
            .replace(new RegExp(",", "gm"), "-");
          this.transport.orderId = this.$route.query.orderId;
          console.log(this.transport);
          addtrafficpage(this.transport).then((res) => {
            if (res.code == 0) {
              this.$message({
                message: "发起成功",
                type: "success",
              });
              // this.transportation = false;
              this.carform();
              this.tralists();
              this.ransportviews = [];
              this.fiel = [];
              this.transport = {
                trafficCount: "",
                startPlace: "",
                trafficWay: "",
                destPlace: "",
                contacts: "",
                contactInfo: "",
                fileIds: [], //运输单据
              };
            }
            console.log(res);
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //支付记录
    paymentpage() {
      let config = {
        orderId: this.$route.query.orderId,
        page: 1,
        size: 10,
      };
      paymentpage(config).then((res) => {
        if (res.code == 0) {
          let list = res.data.records;
          list.forEach((item) => {
            item.createTime = item.createTime.replace("T", " ");
            if (item.payStatus == 0) {
              item.payStatus = "待支付";
            } else if (item.payStatus == 1) {
              item.payStatus = "支付成功";
            } else if (item.payStatus == 2) {
              item.payStatus = "支付失败";
            }
            if (item.payType == 0) {
              item.payType = "未知";
            } else if (item.payType == 1) {
              item.payType = "线下支付";
            } else if (item.payType == 2) {
              item.payType = "线上支付";
            } else if (item.payType == 3) {
              item.payType = "承诺付款";
            }
          });
          this.tableDatas = list;
        }
      });
    },
    //开票列表
    invoicepage() {
      let config = {
        orderId: this.$route.query.orderId,
        page: 1,
        size: 1000000,
      };
      invoicepage(config).then((res) => {
        if (res.code == 0) {
          let list = res.data.records;
          list.forEach((item) => {
            item.createTime = item.createTime.replace("T", " ");
            if (item.trafficWay == 0) {
              item.trafficWay = "未知";
            } else if (item.trafficWay == 1) {
              item.trafficWay = "公路";
            } else if (item.trafficWay == 2) {
              item.trafficWay = "铁路";
            } else if (item.trafficWay == 3) {
              item.trafficWay = "公铁联运";
            } else if (item.trafficWay == 4) {
              item.trafficWay = "海运";
            }
          });
          this.tableData = list;
        }
      });
    },
    //上传图片格式大小检查
    checkPic(file) {
      file = file.file;
      let isJPG;
      if (
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg" ||
        file.type === "image/bmp" ||
        file.type === "application/pdf"
      ) {
        isJPG = true;
      } else {
        isJPG = false;
      }
      const isLt2M = file.size / 1024 / 1024 < 100;
      if (!isJPG) {
        this.$message.error("请上传bmp,jpg,jpeg,png,pdf文件!");
        return false;
      }
      if (!isLt2M) {
        this.$message.error("上传文件大小不能超过 100MB!");
        return false;
      }
      return true;
    },
    // 发起开票选择开票文件
    addinvoiceUrl(file) {
      let pass = this.checkPic(file);
      if (pass) {
        let otherFiles = file.file;
        var formData = new FormData();
        // 文件对象
        formData.append("file", otherFiles);
        ossUploadFile(formData).then((res) => {
          if (res.code == 0) {
            this.$message({
              message: "上传成功",
              type: "success",
            });
            this.form.invoiceUrl = res.data;
            this.$refs.invoiceForm.validateField(["invoiceUrl"]);
          }
        });
      }
    },
    addupdateVoucher(file) {
      let pass = this.checkPic(file);
      if (pass) {
        let otherFiles = file.file;
        var formData = new FormData();
        // 文件对象
        formData.append("file", otherFiles);
        ossUploadFile(formData).then((res) => {
          if (res.code == 0) {
            let config = {
              voucherUrl: res.data,
              paymentId: this.paymentId,
            };
            updateVoucher(config).then((res) => {
              if (res.code == 0) {
                this.$message({
                  message: "上传成功",
                  type: "success",
                });
                this.paymentpage();
              }
            });
          }
        });
      }
    },
    // 上传煤质检验单
    addinspectUrlUrl(file) {
      let pass = this.checkPic(file);
      if (pass) {
        let otherFiles = file.file;
        var formData = new FormData();
        // 文件对象
        formData.append("file", otherFiles);
        ossUploadFile(formData).then((res) => {
          if (res.code == 0) {
            // this.$message({
            //   message: "上传成功",
            //   type: "success",
            // });
            this.receivingForm.inspectUrl = res.data;
          } else {
            this.$message({
              message: res.message,
              type: "error",
            });
          }
        });
      }
    },
    accountClickList(row) {
      this.form.invoiceUrlList = row.invoiceUrlList;
      this.invoiceUrlVis = true;
    },
    // 上传结算单据
    addinvoiceUrlUrl(file) {
      let isPass = this.checkPic(file);
      if (isPass) {
        let otherFiles = file.file;
        var formData = new FormData();
        // 文件对象
        formData.append("file", otherFiles);
        ossUploadFile(formData).then((res) => {
          if (res.code == 0) {
            console.log(res);
            // this.$message({
            //   message: "上传成功",
            //   type: "success",
            // });
            // this.sunbmitpreview.push(res.data.storageName);
            this.receivingForm.invoiceUrl = res.data;
            this.$refs.receivingForm.validateField(["invoiceUrl"]);
          } else {
            this.$message({
              message: res.message,
              type: "error",
            });
          }
        });
      }
    },
    // 上传铁路运输订票
    addreceiptUrlUrl(file) {
      let isPass = this.checkPic(file);
      if (isPass) {
        let otherFiles = file.file;
        var formData = new FormData();
        // 文件对象
        formData.append("file", otherFiles);
        ossUploadFiletw(formData).then((res) => {
          if (res.code == 0) {
            this.$message({
              message: "上传成功",
              type: "success",
            });
            this.sunbmitpreviews.push(res.data.storageName);
            this.receivingForm.receiptUrls.push(res.data.fileId);
          }
        });
      }
    },
    addreceivingUrl(file) {
      let otherFiles = file;
      var formData = new FormData();
      // 文件对象
      formData.append("file", otherFiles);
      ossUploadFile(formData).then((res) => {
        if (res.code == 0) {
          this.$message({
            message: "上传成功",
            type: "success",
          });
          // this.form.invoiceUrl = res.data;
        }
      });
    },
    //发起开票选择开票信息
    addsettleUrl(file) {
      let isPass = this.checkPic(file);
      if (isPass) {
        let otherFiles = file.file;
        var formData = new FormData();
        // 文件对象
        formData.append("file", otherFiles);

        ossUploadFile(formData).then((res) => {
          if (res.code == 0) {
            this.$message({
              message: "上传成功",
              type: "success",
            });
            this.form.invoiceUrl = res.data;
            this.form.invoiceUrlList.push(res.data);
            this.$refs.invoiceForm.validateField(["invoiceUrl"]);
          }
        });
      }
    },
    // 选择合同模板
    selectTem() {
      this.dialogTableVisible = true;
      let id = Vue.ls.get(types.userId);
      let config = {
        pageNum: 1,
        pageSize: 100,
        enterpriseId: id,
        contractStatus: 2,
      };
      listTemplate(config).then((res) => {
        if (res.code == 0) {
          let list = res.data.list;
          list.forEach((item) => {
            if (item.createTime) {
              item.createTime = item.createTime.replace("T", " ");
            }
          });
          this.gridData = list;
        }
      });
    },
    // 查看合同模板
    examineClick(row) {
      console.log(row);
      if (!row) {
        this.$message.error("暂无下载地址");
      } else {
        getContractOSSFile(row, {
          responseType: "blob",
        }).then((res) => {
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(res, row);
          } else {
            // for Non-IE
            let objectUrl = URL.createObjectURL(res);
            let link = document.createElement("a");
            link.href = objectUrl;
            link.setAttribute("download", row);
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(link.href);
            document.body.removeChild(link);
          }
        });
      }
    },
    // 结算票据查看
    accountClicks(row) {
      this.thepreview = row;
      this.dialogopreviews = true;
    },
    // 结算票据查看(预览)
    accountClick(row) {
      if (row) {
        let a = row.split(".");
        if (a[1] == "png" || a[1] == "jpg" || a[1] == "jpeg") {
          this.preview = true;
          this.previewImage = this.baseUrl + "third/third/ossGetFile/" + row;
        } else {
          window.open(this.baseUrl + "third/third/ossGetFile/" + row);
        }
      }
    },
    // 开票信息查看
    invoiceClick(row) {
      console.log(row);
    },
    sponsorClick() {
      this.dialogFormVisible = true;
      let config = {
        orderId: this.$route.query.orderId,
      };
      getNewestInvoice(config).then((res) => {
        if (res.code == 0) {
          this.form.invoiceCount = res.data.invoiceCount;
          this.form.invoiceAmount = res.data.invoiceAmount;
        }
      });
    },
    // 发起开票时上传结算票据
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    // 发起运输
    carriageClick() {
      this.transportation = true;
      if (this.$refs.transport) {
        this.$refs.transport.clearValidate();
      }
    },
    // 发起服务商运输
    carriageClicks() {
      this.transportations = true;
      if (this.$refs.transports) {
        this.$refs.transports.clearValidate();
      }
    },
  },
};
</script>

<style lang="scss">
.el-image__inner {
  top: 0;
  left: 0;
  transform: translate(0, 0);
}
</style>
<style lang="scss" scoped>
.el-form-item__content {
  text-align: left;
}

.img-box {
  position: relative;

  .del-img {
    position: absolute;
    z-index: 100;
    font-size: 26px;
    right: -30px;
    top: 50%;
    margin-top: -15px;
    color: red;
  }
}

.sign {
  ::v-deep {
    .el-dialog__body {
      padding-top: 0;
    }
  }
}

.iframe {
  overflow: auto;
  height: 700px;
}

.sing-btns {
  display: flex;
  margin: 31px 0;

  div {
    width: 98px;
    height: 33px;
    text-align: center;
    line-height: 33px;
    border-radius: 5px;
    color: #333333;
    font-size: 14px;
    font-weight: bold;
  }

  ::v-deep .el-button {
    padding: 0;
    width: 98px;
    height: 33px;
    text-align: center;
    line-height: 33px;
    border-radius: 5px;
    color: #333333;
    font-size: 14px;
  }
}

.order_tabs {
  margin-top: 20px;
}

.pattern {
  display: flex;
  justify-content: space-around;

  div {
    margin: 50px auto;
    text-align: center;
    padding: 10px 20px;
    color: white;
    background: linear-gradient(90deg, #237dfb 0%, #49aefc 100%);
    width: 100px;
    cursor: pointer;
  }
}

.receiving {
  display: flex;
  justify-content: center;
  margin: 20px;

  .sign {
    text-align: center;
    margin: 0 20px;
    padding: 10px 20px;
    color: white;
    font-size: 16px;
    background: linear-gradient(90deg, #237dfb 0%, #49aefc 100%);
    border-radius: 5px;
    // width: 140px;
    cursor: pointer;
  }
}

.el-popover__reference {
  background: linear-gradient(90deg, #237dfb 0%, #49aefc 100%);
}

.previewIm {
  text-align: center;

  img {
    margin: 0 auto;
    width: 200px;
    height: 200px;
  }
}

.first {
  // width: 50%;
  margin: 0 auto;
}

.content {
  // width: 80%;
  margin: 20px auto;
}

.shangchuang {
  width: 6px;
  height: 23px;
  background: linear-gradient(90deg, #237dfb 0%, #49aefc 100%);
  border-radius: 3px;
}

.uploading_all {
  display: flex;

  .unpload {
    margin-left: 10px;
    font-size: 20px;
    font-weight: bold;
  }
}

.uploading {
  display: flex;
  justify-content: space-between;
}

.button_on {
  background: linear-gradient(90deg, #237dfb 0%, #49aefc 100%);
  border-radius: 5px;
  color: white;
  padding: 0 30px;
  margin-left: 10px;
}

.stencil {
  display: flex;
  margin-top: 20px;
  font-size: 16px;
  line-height: 60px;

  .stencil_preview {
    font-size: 20px;
  }

  .template {
    font-size: 20px;
    padding: 20px;
    background: linear-gradient(90deg, #237dfb 0%, #49aefc 100%);
    border-radius: 5px;
    color: white;
    margin-left: 10px;
  }
}

.upload-demo {
  border-radius: 5px;
  color: white !important;
}

.invoice {
  padding: 10px 20px;
  background: linear-gradient(90deg, #237dfb 0%, #49aefc 100%);
  width: 100px;
  color: white;
  border-radius: 5px;
}

.invoices {
  background: linear-gradient(90deg, #237dfb 0%, #49aefc 100%);
  width: 100px;
  color: white;
  border-radius: 5px;
}

.dialogopreviews_item {
  margin: 0 auto;
  text-align: center;

  .item_content {
    display: flex;
    margin: 0 auto;
    margin-top: 20px;

    > :nth-child(1) {
      width: 200px;
    }
  }
}

::v-deep {
  .stencil {
    display: flex;
    line-height: 0;
    margin: 20px 0px;
  }

  .el-upload-list__item {
    width: 100px;
  }

  .hy-btn {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    font-size: 14px !important;
  }

  .el-input__inner {
    width: 211px;
  }
}

::v-deep {
  .el-table {
    border: 1px solid #e5e5e5;

    th {
      background: #f7f7f7;
      border: none;
    }

    td {
      border: none;
      border-bottom: 1px dotted #e5e5e5;
    }
  }
}

.isshipments_item {
  padding: 30px 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .isshipments_item_input {
    border: 1px solid #ccc;
    margin: 0 10px;
  }
}

::v-deep .image_item {
  text-align: center;
}

::v-deep .el-icon-close::before {
  content: "";
}

.danju {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.productItem {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  border-top: 1px solid #ccc;
  padding: 13px 0px;
}

.produitem_img img {
  width: 167px;
  height: 97px;
  border-radius: 2px;
}

.onsale {
  background: #67c23a;
  border-radius: 10px;
  width: 54px;
  color: white;
  text-align: center;
  display: flex;
  justify-content: center;
}

::v-deep .input_size {
  .el-input {
    .el-input__inner {
      width: 100px !important;
    }
  }
}

.el-form-item {
  text-align: left;
}
::v-deep .onlineInforules {
  .onlineInforules-title {
    font-size: 14px;
    font-weight: 600;
    margin: 10px 0;
    i {
      cursor: pointer;
      margin-left: 10px;
    }
  }
  .el-range-editor.el-input__inner {
    width: 100% !important;
  }
  .el-input-number .el-input__inner {
    text-align: left;
  }
  .el-input-number {
    width: 100%;
  }
  .onlineInforules-text {
    font-size: 14px;
    .el-input-number {
      width: 120px;
    }
    .el-input-number .el-input__inner {
      width: 120px;
    }
    .el-form-item {
      vertical-align: middle;
      vertical-align: -webkit-baseline-middle;
      margin-right: 0;
    }
  }
}
</style>
