<template>
  <div class="particu">
    <!-- 详情页面公用组件 -->
    <div class="particu_name">
      <div style="display: flex; justify-content: space-between; width: 100%">
        <div class="title">{{ pageData.goodsName }}</div>
        <div class="number">
          商品编号：<span>{{ pageData.goodsNum }}</span>
        </div>
      </div>

      <div style="margin-top: 10px">
        联系方式：{{ pageData.contacts }} {{ pageData.contactInfo }}
      </div>
    </div>
    <!-- <div class="relation">
      <span style="display: inline-block">线路名称：</span>
    </div> -->
    <div class="remakAll">
      <div class="remarks">
        <div>货物名称：</div>
        <div>{{ pageData.goodsName }}</div>
      </div>

      <div class="remarks">
        <div>中标价：</div>
        <div>
          {{ pageData.unitPrice }}元/吨 {{ pageData.taxIncluded | taxIncluded }}
        </div>
      </div>
      <div class="remarks">
        <div>保证金：</div>
        <div>{{ pageData.bond }}万元</div>
      </div>
      <div class="remarks" style="border-right: none">
        <div>发运量：</div>
        <div>{{ pageData.monthFreightVolume }}吨</div>
      </div>
    </div>
    <h3 style="margin-top: 10px">发布方</h3>
    <div class="remark">
      <div style="width: 100px">支付方式：</div>
      <div>{{ pageData.payType | initPayType }}</div>
    </div>
    <div class="remark">
      <div style="width: 100px">线路名称：</div>
      <div>{{ pageData.lineName }}</div>
    </div>
    <div class="remark">
      <div style="width: 100px">线路类型：</div>
      <div>{{ pageData.lineType | lineType }}</div>
    </div>
    <div class="remark">
      <div style="width: 100px">运距：</div>
      <div>{{ pageData.transportDistance }}</div>
    </div>
    <div class="remark">
      <div style="width: 100px">出发地：</div>
      <div>{{ pageData.originPlace }}{{ pageData.originPlaceDetail }}</div>
    </div>
    <div class="remark">
      <div style="width: 100px">目的地：</div>
      <div>{{ pageData.deliveryPlace }}{{ pageData.deliveryPlaceDetail }}</div>
    </div>
    <div class="remark">
      <div style="width: 100px">竞价时间：</div>
      <div>
        {{ pageData.biddingStartTime | formTime }} ~
        {{ pageData.biddingEndTime | formTime }}
      </div>
    </div>
    <div class="remark">
      <div style="width: 100px">服务期限：</div>
      <div>
        {{ pageData.serviceStartTime | formTime }} ~
        {{ pageData.serviceEndTime | formTime }}
      </div>
    </div>
    <div class="remark">
      <div style="width: 100px">允许综合途耗：</div>
      <div>{{ pageData.allowloss }} ‰</div>
    </div>

    <div
      style="
        margin-top: 20px;
        border-bottom: 1px dashed #ccc;
        padding-bottom: 10px;
      "
    >
      <h3>参与方</h3>
      <div class="relation">
        <span style="display: inline-block; width: 100px">单位名称：</span>
        <span>{{ pageData.partiesName || "" }}</span>
      </div>
      <div class="relation">
        <span style="display: inline-block; width: 100px">联系方式：</span>
        <span>{{ pageData.partiesTelephone || "" }}</span>
      </div>
    </div>

    <div class="remark">
      <div style="width: 100px">备注说明：</div>
      <div>{{ pageData.remark }}</div>
    </div>
  </div>
</template>

<script>
import { tradeDtetails, orderDetail } from "@/api/public.js";
import { listTbOrderDeatil } from "@/api/Logistics.js";
export default {
  data() {
    return {
      tradeGoods: {},
      tradeGoodsListing: {},
      quota: [],
      tradeBidding: null,
      pageData: {},
      selectOrderType: null,
      ifInvite: null,
    };
  },
  components: {
    // CountDown: () => import("@/components/countDowns.vue"),
  },
  filters: {
    listingPrice(data) {
      if (data || data == 0) {
        return data == 0 ? "面议" : "￥" + data + "/吨";
      } else {
        return "";
      }
    },
    taxIncluded(val) {
      let arr = ["含税", "不含税"];
      return arr[val];
    },
    initPayType(val) {
      let arr = ["未知", "现汇", "承兑", "现汇+承兑"];
      return arr[val];
    },
    lineType(val) {
      let arr = ["", "短名单模式","公开模式"];
      return arr[val];
    },
    formTime(val) {
      if (val) {
        return val.replace("T", " ");
      }
    },
  },
  mounted() {
    this.selectOrderType = this.$route.query.selectOrderType;
    this.ifInvite = this.$route.query.ifInvite;
    this.orderList(this.$route.query.orderId);
    // console.log(this.$route.query.ifInvite,'哈哈哈哈哈哈哈哈哈哈哈')
    // if (this.$route.query.goodsId) {
    //   this.getlisst(this.$route.query.goodsId);
    // } else if (this.$route.query.orderId) {
    //   this.orderList(this.$route.query.orderId);
    // }
  },
  props: {},
  methods: {
    getlisst(id) {
      // let goodsId = this.$route.query.goodsId;
      tradeDtetails(id).then((res) => {
        console.log("tradeDtetails-start");
        console.log(res);
        if (res.code == 0) {
          this.pageData = res.data;
          if (res.data.quotas) {
            let quotas = res.data.quotas;
            quotas.forEach((item) => {
              item.name =
                item.name +
                `(${item.unit.split(";")[0]})` +
                `${item.unit.split(";")[2]}`;
              item.value = item.value + `${item.unit.split(";")[1]}`;
            });
            this.quota = quotas;
          }
          if (res.data.tradeGoods) {
            let newlist = res.data.tradeGoods;
            if (newlist.createTime) {
              newlist.createTime = newlist.createTime.replace("T", " ");
            }
            newlist.createTime = newlist.createTime.replace("T", " ");
            if (newlist.deliveryStartTime) {
              newlist.deliveryStartTime = newlist.deliveryStartTime.replace(
                "T",
                " "
              );
            }
            if (newlist.deliveryEndTime) {
              newlist.deliveryEndTime = newlist.deliveryEndTime.replace(
                "T",
                " "
              );
            }
            this.tradeGoods = newlist;
          }
          if (res.data.tradeBidding) {
            let list = res.data.tradeBidding;
            for (const key in list) {
              if (key == "startTime") {
                list[key] = list[key].replace("T", " ");
              }
              if (key == "endTime") {
                list[key] = list[key].replace("T", " ");
              }
            }
            this.tradeBidding = list;
          }
          this.$emit("child-event", res.data);
          if (res.data && res.data.tradeGoodsListing) {
            this.tradeGoodsListing = res.data.tradeGoodsListing;
          } else {
            this.tradeGoodsListing = null;
          }
          console.log(this.tradeGoodsListing, "this.tradeGoodsListing");
        }
      });
    },
    orderList(id) {
      listTbOrderDeatil(id).then((res) => {
        if (res.code == 0) {
          this.pageData = res.data;
          if (res.data.quotas) {
            let quotas = res.data.quotas;
            quotas.forEach((item) => {
              item.name =
                item.name +
                `(${item.unit.split(";")[0]})` +
                `${item.unit.split(";")[2]}`;
              item.value = item.value + `${item.unit.split(";")[1]}`;
            });
            this.quota = quotas;
          }
          this.$emit("child-event", res.data);
          console.log(res.data, "this.pageData");
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.item_images {
  width: 100px;
  height: 24px;
  background: url("../assets/images/tag.png") no-repeat;
  background-size: 100px 100%;
  color: white;
  padding-left: 3px;
  padding-top: 2px;
  margin-top: 10px;
  display: flex;
}
.particu_cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px dashed #ccc;
  border-bottom: 1px dashed #ccc;
  margin-top: 20px;
}
.particu {
  padding: 20px;
  border: 1px solid #ccc;
  .particu_name {
    // display: flex;
    // justify-content: space-between;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;

    .title {
      font-size: 18px;
      font-weight: bold;
    }
    .number {
      margin-top: 5px;
      // margin-left: 20px;
    }
  }
  .particu_items {
    display: flex;
    align-content: center;
    // justify-content: space-between;
    // padding: 20px;
    padding: 20px 0;
    // border: 1px solid #ccc;
    // margin-top: 20px;
  }
  .particu_item {
    display: flex;
    // justify-content: space-between;
    // padding: 20px;
    padding: 20px 0;
    // border: 1px solid #ccc;
    border-top: 1px dashed #ccc;
    border-bottom: 1px dashed #ccc;
    margin-top: 20px;
  }
  .auction {
    margin-top: 20px;
    .auction_item {
      // display: flex;
      margin-top: 20px;
      .finish {
        // margin-left: 20px;
        margin-top: 20px;
      }
    }
  }
  .relation {
    margin-top: 20px;
  }
  .delivery {
    margin-top: 20px;
  }
  .indicator {
    margin-top: 20px;
    display: flex;
    .tables {
      // margin-top: 10px;
      text-align: center;
      table {
        border: none;
      }
      .tables_tr {
        // display: flex;
        flex-wrap: wrap;
        font-weight: bold;
      }
      td {
        border: none;
        width: 220px;
        padding: 5px 10px;
        border: 1px dashed black;
      }
    }
  }
  .remark {
    margin-top: 20px;
    display: flex;
  }
  .remarks {
    // margin-top: 20px;
    width: 200px;
    border-right: 1px solid #ccc;
    display: flex;
  }
}
.remakAll {
  display: flex;
  border: 1px solid #ccc;
  margin-top: 10px;
  align-items: center;
  // line-height: ;
  justify-content: space-between;
  padding: 10px 10px;
}
</style>
