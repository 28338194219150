<template>
  <div class="insilde">
    <div class="sign">
      <div style="display:flex">
        <el-button type="primary" @click="sign(1)">在线签章</el-button>
      <el-button type="primary" @click="sign(3)">在线骑缝签章</el-button>
      </div>
      <img
        src="../../../assets/zxx_img/icons_clock.png"
        alt=""
        @click="close()"
        srcset=""
      />
      <!-- <el-button type="primary" @click="save">保存</el-button> -->
    </div>
    <iframe
	style="flex:1;width: 100%;"
      frameborder="0"
      :src="serverUrl + '/iCloudFrontLoader/pc#/'"
      id="topFrame"
      name="topFrame"
    ></iframe>
  </div>
</template>

<script>
// import $ from "jquery";
import { getSealCompositeCallback } from "@/api/userCenters.js";
import signBaseUrl from "@/config/signConfig";
import * as ESASignClient from "@/utils/signConfig/ESASignClient.js";

export default {
  props: ["documentId", "Softsign", "signaturePwd", "signatureName"],
  data() {
    return {
      serverUrl: "", // 云文档后台服务地址  https://doc.easysign.cn
      title: 1,
    };
  },
  computed: {},
  created() {
   
    this.serverUrl = signBaseUrl.signBaseUrl;
  },
  mounted() {
    let _this = this;
    addEventListener("message", function (e) {
      // e.data为子页面发送的数据
      console.log(e.data, "接收数据");
      let res = e.data;
      console.log(res, res.type, "接收数据类型----------------------");
      switch (
        res.type // 回调类型（对应接口文档通知事件的type）
      ) {
        case "openFile": // 打开文档完成事件回调
          if (res.code == 0) {
            // to do
          }
          break;
        case "signCompelete": // 签章完成事件回调
          // 调下载接口
          // to do
          _this.callBack();
          break;
      }
    });

    let iframe = document.getElementById("topFrame");
    iframe.onload = () => {
      console.log("加载完成"); // 这样每次都会触发
      this.uploadFile();
    };
  },
  watch: {},
  methods: {
    close() {
      console.log(1111);
      this.$emit("closeSign", false);
    },
    callBack() {
      // let paramObj = {
      //   documentId: this.documentId,
      // };
      getSealCompositeCallback(this.documentId)
        .then((res) => {
          if (res.code == 0) {
            console.log(res);

            // this.$message({
            //   message: "签章成功",
            //   type: "success",
            // });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    sign(title) {
		console.log("11111111");
      this.title = title;
      let _this = this;
      if (_this.Softsign) {
        let params;
        if (_this.Softsign == 1) {
          params = {
            type: "setSealSource",
            seal_type: 1,
            source_type: 4,
            sub_seal_obj: {
              get_type: "2",
              get_info: {
                account: _this.signatureName, // get_type == 2  账号
                sign_pwd: _this.signaturePwd, // get_type == 2   签章密码
              },
            },
          };
          _this.sendMsg(params);
        } else if (_this.Softsign == 2) {
          params = {
            type: "setSealSource",
            seal_type: 1,
            source_type: 4,
            sub_seal_obj: {
              get_type: "3",
              get_info: {
                name: _this.signatureName, // get_type == 2  账号
                card_id: _this.signaturePwd, // get_type == 2   签章密码
              },
            },
          };
          _this.sendMsg(params);
        }

          //2022.03.19.贾二磊.开始
          //处理开发问题，在进行电子签章操作时，用户点击签章处的确认按钮后，前端页面调用签章服务saveSignature接口，参数中cert_source实际值为1，应该是4。不清楚get_type含义，所以当前只修改了和三方给的示例中账号+密码.html文件中与当前参数相同的get_type值为2的代码
          params = {
            type: "setCertSource",
            cert_source: 4,
          };
          _this.sendMsg(params);
          //2022.03.19.贾二磊.结束
        let param = {
          type: "setSealPoisiton",
          allow_drag: true,
          position_type: title,
          coordinate_obj: {},
         
        };
        if(title==3){
           param.seam_obj= {
            // 骑缝可拖动
            seam_type: 1,
            // y_position: 100,
            count: 100, // 印章切割数
            seal_way: 0, // 盖章页范围 0、所有页  1、奇数页  2、偶数页
          }
        }
        _this.sendMsg(param);
      } else {
        ESASignClient.ESACheckIsKey(_this.JsESACheckIsKey, null, false);
      }
    },
    JsESACheckIsKey(status, msg, retpar, params) {
      let _this = this;

      if (status == 0) {
        ESASignClient.ESAVerifyPin(_this.JsESAVerifyPin, null, false);
      } else {
        alert(msg);
      }
    },
    JsESAVerifyPin(status, msg, retpar, params) {
      let _this = this;
      console.log(status);
      if (status == 0) {
        let param = {
          type: "setSealPoisiton",
          allow_drag: true,
          position_type: this.title,
          coordinate_obj: {},
          
        };
        if(this.title==3){
          param.seam_obj= {
            // 骑缝可拖动
            seam_type: 1,
            // y_position: 100,
            count: 100, // 印章切割数
            seal_way: 0, // 盖章页范围 0、所有页  1、奇数页  2、偶数页
          }
        }
        _this.sendMsg(param);
      } else {
        alert(msg);
      }
    },
    save() {
      //   let param = {
      //     type: "saveFile",
      //   };
      //   this.sendMsg(param);
    },
    uploadFile() {
      console.log(this.documentId);
      let _this = this;
      let param = {
        type: "viewFile",
        version: "1.0",
		load_type: "1",
        file_id: this.documentId, // 上传文档会返回
        // file_id: "4417aeddb47d4215a558a339ed4a9a32", // 上传文档会返回
        user_id: "111",
        // iCloud_UI: {
        //     hidden: false  // 展示顶部、左边、右边工具栏
        // }
      };
      _this.sendMsg(param);
    },
    // // 云文档回调事件

    sendMsg(param) {
      console.log(param);
      let data = JSON.stringify(param);
      document.getElementById("topFrame").contentWindow.postMessage(data, "*");
    },
  },
};
</script>

<style scoped lang="scss">
.sign {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  img {
    width: 20px;
    height: 20px;
  }
}
.insilde {
  height: 700px;
  display: flex;
  flex-flow: column;
}
</style>
