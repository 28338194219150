<template>
  <div class="main">
   
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%"  :show-close='!dialogVisible'>
       <img class='close' @click="dialogVisible=false" src="../assets/zxx_img/icons_clock.png" alt="">
      <div class="title">如使用签章(Ukey),请先下载驱动。</div>
      <div class="text">
        <div>
          <span>文鼎创管理工具(用户版)</span>
          <span @click="download1">下载</span>
        </div>
        <div>
          <span>电子签名认证服务</span>
          <span @click="download2">下载</span>
        </div>
      </div>
      <div class='text'>驱动下载并完成安装，再插入签章(Ukey)<p>（如果已安装请忽略！）</p></div>
    </el-dialog>
  </div>
</template>

<script>
const { baseUrl } = require("@/config");
export default {
  props: {},
  data() {
    return {
      dialogVisible: false,
      baseUrl:baseUrl
    };
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {},
  methods: {
    changeState() {
      this.dialogVisible = true;
    },
    download1() {
      let aTag = document.createElement("a");
      aTag.download = this.file_name;
      aTag.href = "https://api.xjcec.com/third/third/ossGetFile/20220427151240385-95.exe";
      console.log(aTag);
      aTag.click();
    },
    download2() {
      let aTag = document.createElement("a");
      aTag.download = this.file_name;
      aTag.href ="https://api.xjcec.com/third/third/ossGetFile/20220427151430181-35.exe";
      console.log(aTag);
      aTag.click();
    },
  },
  components: {},
};
</script>

<style scoped lang="scss">
.main {
  text-align: center;
  position: relative;
  .title {
    font-size: 24px;
  }
  .close{
    position: absolute;
    top:20px;
    right: 20px;
    width: 15px;
    height: 15px;
  }
  .text {
    text-align: left;
    margin-top: 35px;
    div {
      display: flex;
      justify-content: space-between;
      margin-top: 15px;
      span:last-child {
        color: #2440b3;
        cursor: pointer;
      }
    }
    p{
        display:inline-block;color:#f73131;
    }
  }
}
</style>
