import http from '@/utils/request'
export const accountOpenForZJ = (data) => http.post('/finance/zjPay/accountOpenForZJ', data)
export const getAccountStatusForZJ = (data) => http.post('/finance/zjPay/getAccountStatusForZJ', data)
export const listBankCode = (data) => http.post('/finance/zjPay/listBankCode', data)
export const bindCardForZJ = (data) => http.post('/finance/zjPay/bindCardForZJ', data)
export const bindCardConfirmForZJ = (data) => http.post('/finance/zjPay/bindCardConfirmForZJ', data)
export const signContractForZJ = (data) => http.post('/finance/zjPay/signContractForZJ', data)
export const listAccount = (data) => http.post('/finance/zjPay/listAccount', data)
export const unbindCardForZJ = (data) => http.post('/finance/zjPay/unbindCardForZJ', data)
export const getCardBindStatusForZJ = (data) => http.post('/finance/zjPay/getCardBindStatusForZJ', data)
export const getAccountBalanceByParam = (data) => http.post('/trade/tradeGoods/getAccountBalanceByParam', data)
export const rechargeOnlineForZJ = (data) => http.post('/finance/zjPay/rechargeOnlineForZJ', data)
export const WithDraw = (data) => http.post('/finance/financeWithdraw/withdraw', data)
export const downloadEleReceipt = (data) => http.post('/finance/zjPay/downloadEleReceipt', data)
